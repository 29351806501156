import React, {useState, useEffect} from 'react';
import {Row, Col, Button, Image, Ratio, Container} from 'react-bootstrap';
import {ChevronCompactRight} from 'react-bootstrap-icons';
import {Link} from 'react-router-dom';
import axios from 'axios';

const containerStyle={
	textAlign: 'left',
	paddingTop: '1rem',
	paddingBottom: '1rem',
	borderRadius: '1rem',
	boxShadow: '0px -1px 5px rgba(0, 0, 0, 0.2)',
	//backgroundColor: '#ceb2e8',
	backgroundColor: 'white',
	border: '1px solid #c3c3c3',
};
const imageStyle={
	backgroundColor: 'white',
	borderRadius: '1rem',
};

function MyProfile() {

	const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;

	//데이터 받아오기 JWT 복호화 데이터
        const [myProfileInfo, setData] = useState(null);
        useEffect(()=>{
	        const fetchData = async() => {
			const token = localStorage.getItem('token');
			console.log(token);
			const response = await axios.post(`${apiUrl}/MyProfile`,{ myToken: token });
                        await setData(response.data);
                        await console.log(response.data);
                };  
                fetchData();
        },[]);

	return (
	<div style={{padding: '10px', paddingTop: '20px', borderBottomLeftRadius: '1rem', borderBottomRightRadius: '1rem', backgroundColor: '#ffc107'}}>
	    <h3><b>내 프로필</b></h3>
		<Container style={containerStyle}>
	    <Row>
		{myProfileInfo && (
			<>
			<Link to='/profile' style={{textDecoration: 'none', color: 'inherit', display: 'inline-flex', padding:0}}>
			    <Col xs={2} className={""} style={{padding: "0", width:'14%', marginLeft: '10px'}}>
				<Ratio aspectRatio="1x1" style={imageStyle}>
				<Image src={myProfileInfo.loginUser.pimage} roundedCircle fluid />
				</Ratio>
				</Col>
			      <Col xs={7} style={{paddingLeft: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
				<Row><b>{myProfileInfo.loginUser.nname}</b></Row>
				<Row><p style={{marginBottom:0}}>{myProfileInfo.loginUser.message}</p></Row>
			      </Col>
			      <Col xs={3} style={{textAlign: 'right', paddingRight: '10px'}}>
				  <ChevronCompactRight />
			      </Col>
			</Link>
			</>
		)}
	    </Row>
	  </Container>
	  <div className="text-center">
		<hr style={{paddingLeft: '35%', paddingRight: '35%', display:"inline-block"}}/>
	  </div>
	</div>
	);
}

export default MyProfile;
