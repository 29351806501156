import {Container, Image} from 'react-bootstrap';
import {useState, useEffect} from 'react';


function SettingAdvertisement(){

	return(
	    <Container style={{marginTop: '2.5rem', textAlign:'left'}}>
		<Image src="/advertisement_1.png" fluid/>
	    </Container>
	);
}

export default SettingAdvertisement;
