import React, { useState, useEffect } from 'react';
import {Row, Col, Ratio, Image } from 'react-bootstrap';
import {PersonArmsUp} from 'react-bootstrap-icons';
import {Link} from 'react-router-dom';
import axios from 'axios';

//시간 변경하는 함수
const getTimeAgo = (timestamp) => {
  const now = new Date();
  const then = new Date(timestamp);
  const diff = Math.floor((now - then) / 1000); // difference in seconds
  if(timestamp){
      if (diff < 60) {
        return '방금 전';
      } else if (diff < 3600) {
        return `${Math.floor(diff / 60)}분 전`;
      } else if (diff < 86400) {
        return `${Math.floor(diff / 3600)}시간 전`;
      } else if (diff < 604800) {
        return `${Math.floor(diff / 86400)}일 전`;
      } else {
        return '오래 전';
      }
  }else{
      return 'ㅡ';
  }
};

//style 모음
const timeStyle={fontSize: '0.6rem',margin: 0,textAlign: 'right', paddingRight: '15px'};
const memberStyle={fontSize: '0.7rem', margin:0, textAlign: 'right'};
const ChatRoomName = {padding: 0,margin: 0, marginRight:'5px', fontWeight: 'bold'};
const displayDot = {overflow: 'hidden', textOverflow : 'ellipsis', whiteSpace: 'nowrap',display: 'block', color: '#787878'};
const messageStyle = {padding: 0};
const classOpenMessage = {padding: 0, color:'#787878'};
const noDataStyle = {width:'100%', textAlign:'center', padding: '1rem'};
const containerStyle={
        textAlign: 'left',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        borderRadius: '1rem',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        backgroundColor: '#cab0df',
	marginTop: '0.5rem'
};
const imageStyle={
        backgroundColor: 'white',
        borderRadius: '1rem',
};



function ChatLocation() {

	const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
	const token = localStorage.getItem('token');

	const [mainLo, setMainLo] = useState([]);
	const [subLo, setSubLo] = useState([]);
	const [nowLo, setNowLo] = useState([]);
	const [countResults, setCountResults] = useState([]);

	useEffect(() => {
	    const fetchData = async () => {
		const response = await axios.post(`${apiUrl}/ChatMyLocationRoom`,{ myToken: token });
		setMainLo(response.data.mainloRooms);
		setSubLo(response.data.subloRooms);
		setNowLo(response.data.locationInfo);
		setCountResults(response.data.countResults);
		console.log('response: ',response);
		console.log('mainLo: ', response.data.mainloRooms);
		console.log('subLo: ', response.data.subloRooms);
	    };
	
	    fetchData();

	},[]);


	const mainLoInfo = mainLo.map((v, index) => {
	
	    const countResult = countResults.find(result => result.rid === v.rid);
	    const count = countResult ? countResult.count : 0; // 해당 항목이 없으면 기본값 0

	    return (

	    <div key={index} style={{backgroundColor: 'white', borderRadius: '1rem', padding: '0.8rem', marginBottom: index === 0 ? '0.5rem' : '0'}}>
		<Link style={{textDecoration: 'none', color: 'black'}} to={'/chatRoom/'+v.rid}>
		<Row style={{marginTop:'10px'}}>
		    <div>
			<div>
			    <span style={{padding: 0, textAlign: 'center'}}>
				{<span style={ChatRoomName}>{v.roomName}</span>}
				<span style={memberStyle}>{v.userCount}</span>
			    </span>
			</div>
			<div style={displayDot}>
			    {v.latestMessage.mType === 'c' 
				? <div style={classOpenMessage}><PersonArmsUp />모임 개최</div>
				: v.latestMessage.mType === 'i' ? (
				  <div style={classOpenMessage}>이미지</div>
				) : v.latestMessage.message ? (
				  v.latestMessage.message
				) : (
				  <div style={messageStyle}>메시지 없음</div>
				)}
			</div>
		    </div>
		    <div xs={2} style={timeStyle}>
		    	{count !== 0 && (
			<span style={{marginRight: '1rem', backgroundColor: '#ffdf00', padding: '2px 5px', borderRadius: '1rem', fontWeight: 'bold'}}>
		    		{count}
			</span>
			)}
			<span>{getTimeAgo(v.latestMessage.createAt)}</span>
		    </div>
		</Row>
		</Link>
	    </div>
	);
	});

	const subLoInfo = subLo.map((v, index) => {
	
	    const countResult = countResults.find(result => result.rid === v.rid);
	    const count = countResult ? countResult.count : 0; // 해당 항목이 없으면 기본값 0

	    return (
	    <div key={index} style={{backgroundColor: 'white', borderRadius: '1rem', padding: '0.8rem', marginBottom: index === 0 ? '0.5rem' : '0'}}>
		<Link style={{textDecoration: 'none', color: 'black'}} to={'/chatRoom/'+v.rid}>
		<div style={{marginTop:'10px'}}>
		    <div>
			<div>
			    <span style={{padding: 0, textAlign: 'center'}}>
				{<span style={ChatRoomName}>{v.roomName}</span>}
				<span style={memberStyle}>{v.userCount}</span>
			    </span>
			</div>
			<div style={displayDot}>
			    {v.latestMessage.mType === 'c' 
				? <div style={classOpenMessage}><PersonArmsUp />모임 개최</div>
				: v.latestMessage.mType === 'i' ? (
				  <div style={classOpenMessage}>이미지</div>
				) : v.latestMessage.message ? (
				  v.latestMessage.message
				) : (
				  <div style={messageStyle}>메시지 없음</div>
				)}
			</div>
		    </div>
		    <div xs={2} style={timeStyle}>
		       {count !== 0 && (
			<span style={{marginRight: '1rem', backgroundColor: '#ffdf00', padding: '2px 5px', borderRadius: '1rem', fontWeight: 'bold'}}>
		    	    {count}
			</span>
		       )}
			<span>{getTimeAgo(v.latestMessage.createAt)}</span>
		    </div>
		</div>
		</Link>
	    </div>
	    );
	});

return (

<div style={{
    position: 'relative',
    padding: '10px',
    paddingTop: '20px',
    backgroundColor: '#e5e5e5',
    borderBottomLeftRadius: '1rem',
    borderBottomRightRadius: '1rem',
    overflow: 'hidden'
}}>
    {/* 배경 이미지 (반투명) */}
    <div style={{
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: "url('/chatMainIcon.png')",
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        zIndex: 1
    }}></div>

    {/* 내용 */}
    <div style={{ position: 'relative', zIndex: 2 }}>
        <Row>
            <Col style={{ paddingRight: '0.3rem', width: '50%' }}>
                <Row style={{ textAlign: 'center', padding: '1rem 1rem 0 0' }}>
                    <h3 style={{ marginBottom: 0, padding: '0.7rem', backgroundColor: '#FFFFFFC9', borderRadius: '0.8rem', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}><b>우리동네</b></h3>
                </Row>
                <div style={{ ...containerStyle, paddingLeft: '5px', paddingRight: '5px' }}>
	            <div style={{ textAlign: 'center', width: '100%', margin: '0.4rem 0', fontWeight: 'bold' }}>
	                {nowLo && nowLo[0]}
	            </div>
                    {mainLoInfo && mainLoInfo.length > 0 ? mainLoInfo : (
                        <div style={{ paddingTop: '1rem', textAlign: 'center' }}>
                            <Image src='/noLocation.png' style={{ maxWidth: '25%' }} />
                            <div style={noDataStyle}>
                                위치 인증을 해주세요
                            </div>
                        </div>
                    )}
                </div>
                <br />
            </Col>
            <Col style={{ paddingLeft: '0.3rem', width: '50%' }}>
                <Row style={{ textAlign: 'center', padding: '1rem 0 0 1rem' }}>
                    <h3 style={{ marginBottom: 0, padding: '0.7rem', backgroundColor: '#FFFFFFC9', borderRadius: '0.8rem', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}><b>자주가는 동네</b></h3>
                </Row>
                <div style={{ ...containerStyle, paddingLeft: '5px', paddingRight: '5px' }}>
	            <div style={{ textAlign: 'center', width: '100%', margin: '0.4rem 0', fontWeight: 'bold' }}>
	                {nowLo && nowLo[1]}
	            </div>

                    {subLoInfo && subLoInfo.length > 0 ? subLoInfo : (
                        <div style={{ paddingTop: '1rem', textAlign: 'center' }}>
                            <Image src='/noLocation.png' style={{ maxWidth: '25%' }} />
                            <div style={noDataStyle}>
                                위치 인증을 해주세요
                            </div>
                        </div>
                    )}
                </div>
            </Col>
        </Row>
    </div>
</div>


);

}

export default ChatLocation;
