import React, { useState } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { authService } from '../firebase'; // Firebase 설정 import
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import {ChevronCompactLeft } from 'react-bootstrap-icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


function LoginAuth({ onNextClick }) {

  const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [message, setMessage] = useState('');
  const [message2, setMessage2] = useState('');
  const [verificationCodeSet, setVerificationCodeSet] = useState(false);
  const [name, setName] = useState('');
  const [idNumber, setIdNumber] = useState({ part1: '', part2: '' });
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(true);

  const handleSendCode = async () => {
    if (!phoneNumber) {
      setMessage('전화번호를 입력하세요.');
      return;
    }
    setMessage2('인증번호가 전송중입니다.');
    console.log('phoneNumber : ', phoneNumber);
    try {
      if (phoneNumber === '01011111111' || phoneNumber === '01022222222') {
	      console.log('It is Test number1');
	      // Test 번호일 경우 즉시 확인 코드 단계로 이동
	      setVerificationCodeSet(true);
	      handleVerifyCode(); // 바로 인증 확인 단계로 이동
      }else{

	     setIsNextButtonEnabled(false); // 버튼 비활성화
	      // reCAPTCHA 생성
	      window.recaptchaVerifier = new RecaptchaVerifier(authService, 'recaptcha-container', {
	        size: 'invisible',
	      });
	
	      await window.recaptchaVerifier.render(); // reCAPTCHA 렌더링
	
	      const fullPhoneNumber = `+82${phoneNumber}`; // 한국 국제전화 번호를 포함한 전화번호 형식
	      const confirmationResult = await signInWithPhoneNumber(authService, fullPhoneNumber, window.recaptchaVerifier);
	      setConfirmationResult(confirmationResult);
	      setMessage('인증 코드가 발송되었습니다.');
	
	      setVerificationCodeSet(!verificationCodeSet);
      }
    } catch (error) {
      console.error('코드 전송 오류:', error);
      setMessage(error.message);
      setIsNextButtonEnabled(true); // 실패 시 버튼 다시 활성화
    }
  };

  const handleVerifyCode = async () => {
    if (!confirmationResult  && !(phoneNumber === '01011111111' || phoneNumber === '01022222222')) {
      setMessage('먼저 인증 코드를 받아야 합니다.');
      return;
    }

    try {
    	let user
    	if (phoneNumber === '01011111111') {
	      console.log('It is Test number1');
	      user = {};
	      user.uid = 'testuid1'; // Test 번호 +8211111111일 경우
	      
    	}else if (phoneNumber === '01022222222') {
	      console.log('It is Test number2');
	      user = 'testuid2' ; // Test 번호 +8222222222일 경우
	}else{
	      const result = await confirmationResult.confirm(verificationCode); // result 변수를 정의합니다.
	      user = result.user;
	      // 인증 성공 후 추가 처리 (예: 로그인 상태 관리)
	      console.log('###########user ID: ', user);
	}
	      setMessage('전화번호 인증이 성공적으로 완료되었습니다.');
	      const response = await axios.post(`${apiUrl}/UserJoinCheck`, { phone: phoneNumber, name: name, idNumber: idNumber, uid: user.uid });
	
	      console.log('response Result: ',response);
	      switch(response.data.res){
		      case 'loginSuccess': 
			      const token = response.data.token;
			      localStorage.setItem('token',token);
			      console.log('loginSuccess');
			      navigate('/friend');
			      break;
		      case 'fail':
			      setMessage(response.data.message);
			      console.log('loginFail');
			      break;
		      case 'ageFail':
			      setMessage(response.data.message);
			      break;
		      case 'alreadyUsePhone':
			      setMessage(response.data.message);
			      break;
		      default:
			      console.log('defualt');
			      break;
	      }
    } catch (error) {
      console.error('코드 확인 오류:', error);
      setMessage('코드 확인에 실패했습니다.');
    }
  };

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;
    const numericValue = input.replace(/[^0-9]/g, ''); // 숫자가 아닌 문자는 제거
    setPhoneNumber(numericValue);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleIdNumberChange = (e, part) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, ''); // 숫자만 입력받음
    setIdNumber((prev) => ({ ...prev, [part]: numericValue }));
  };

  const isButtonEnabled = name && idNumber.part1.length === 6 && idNumber.part2.length === 1 && phoneNumber;

  return (
    <div style={{ backgroundColor: 'f7f7f7', padding: '20px', maxWidth: '500px', margin: '0 auto', textAlign: 'left' }}>
      <Row style={{marginBottom: '1.5rem', textAlign: 'left'}}>
	<Col xs={4} onClick={()=> {
		onNextClick();
	}}>
	  <ChevronCompactLeft size={15} style={{display:'inlineBlock'}}/><span> 뒤로가기 </span>
	</Col>
	<Col></Col>
      </Row>
      <h2 style={{ fontWeight: 'bold', marginBottom: '20px' }}>INNERDONG 이용을<br /> 위해 본인확인을 해주세요.</h2>
      <p style={{marginTop: '1rem', color: '#1740d2'}}>문제 발생 시 '뒤로가기' 클릭 후 다시 시도해보세요.</p>
      <Form.Group controlId="formName">
        <Form.Label>이름</Form.Label>
        <Form.Control
          type="text"
          placeholder="이름을 입력하세요"
          value={name}
	  inputMode="text"
          onChange={handleNameChange}
          style={{ fontSize: '0.8rem', padding: '10px' }}
        />
      </Form.Group>

      <Form.Group controlId="formIdNumber" style={{ marginTop: '15px' }}>
        <Form.Label>주민등록번호</Form.Label>
        <Row>
          <Col xs={6}>
            <Form.Control
              type="text"
              maxLength={6}
              placeholder="앞자리 6자리"
              value={idNumber.part1}
	      inputMode="numeric"
              onChange={(e) => handleIdNumberChange(e, 'part1')}
              style={{ fontSize: '0.8rem', padding: '10px' }}
            />
          </Col>
          <Col xs={6}>
            <Form.Control
              type="text"
              maxLength={1}  // 뒷자리는 1글자만 입력 가능
              placeholder="뒷자리 첫 숫자"
              value={idNumber.part2}
	      inputMode="numeric"
              onChange={(e) => handleIdNumberChange(e, 'part2')}
              style={{ fontSize: '0.8rem', padding: '10px' }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group controlId="formPhoneNumber" style={{ marginTop: '15px' }}>
        <Form.Label>휴대폰 인증</Form.Label>
        <Row>
          <Col xs={3}>
            <Form.Control as="select" style={{ fontSize: '0.8rem', padding: '10px' }}>
              <option>통신사 선택</option>
              <option>SKT</option>
              <option>KT</option>
              <option>LG U+</option>
            </Form.Control>
          </Col>
          <Col xs={6}>
            <Form.Control
              type="text"
              placeholder="01012341234"
              value={phoneNumber}
	      inputMode="numeric"
              onChange={handlePhoneNumberChange}
              maxLength={11}
              style={{ fontSize: '0.8rem', padding: '10px' }}
            />
          </Col>
          <Col xs={3}>
            <Button
              variant={isButtonEnabled ? 'warning' : 'secondary'}
              disabled={!isButtonEnabled || !isNextButtonEnabled}
              onClick={handleSendCode}
              style={{ padding: '10px', fontSize: '0.8rem' }}
            >
              인증
            </Button>
          </Col>
        </Row>
      </Form.Group>
      {message2 && (<div style={{color:'red', fontSize: '0.7rem'}}>{message2}</div>)}
      {verificationCodeSet && (
        <>
          <Form.Group controlId="formVerificationCode" style={{ marginTop: '20px' }}>
            <Form.Label>인증 코드 입력</Form.Label>
            <Row>
              <Col xs={8}>
                <Form.Control
                  type="text"
                  placeholder="인증 코드 입력"
                  value={verificationCode}
	          inputMode="numeric"
                  onChange={(e) => setVerificationCode(e.target.value)}
                  style={{ fontSize: '0.8rem', padding: '10px' }}
                />
              </Col>
              <Col xs={4}>
                <Button onClick={handleVerifyCode} variant="warning" style={{ padding: '10px', fontSize: '0.8rem' }}>
                  확인
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </>
      )}

      <div id="recaptcha-container" style={{ display: 'none' }}></div>
      {message && <p style={{ color: 'red', marginTop: '20px' }}>{message}</p>}
    </div>
  );
}

export default LoginAuth;



