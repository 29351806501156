import {Row, Col, Button, Image, Ratio, Container, Modal, Form} from 'react-bootstrap';
import {X} from 'react-bootstrap-icons';
import {useState, useEffect} from 'react';
import {NotionRenderer} from 'react-notion-x';
import { Collection } from 'react-notion-x/build/third-party/collection';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'react-notion-x/src/styles.css'

const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
const token = localStorage.getItem('token');

const tableStyle={width: '100%'};
const titleStyle={fontWeight: 'bold', textAlign:'center', backgroundColor: '#eeeeee', borderRadius: '1rem', padding: '0.5rem'};
const contentDetailStyle={fontSize: '0.9rem', position:'absolute', top:0, left:0, width: '100%', height:'100vh', backgroundColor: 'white', padding:'1rem', textAlign:'left'};

function SettingNotion(){
	const navigate = useNavigate();
	const [notionData, setNotionData] = useState(null);
	useEffect(() => {
	    try{
	        const fetchData = async() => {
		    const response = await axios.get(`${apiUrl}/NotionData`);
	            console.log(response);
		    setNotionData(response.data);
	        };
	        fetchData();
	    }catch(error){
		console.log(error);
	    }

	},[]);

	const [recordMap, setRecordMap] = useState('');
	const handleShowNotice = async(noticeId) => {
	    console.log(noticeId);
	    const response = await axios.post(`${apiUrl}/ShowNotice`, {noticeId: noticeId});
	    console.log(response.data);
	    setRecordMap(response.data);
	};

	const handleCloseNotice = () => {
	    setRecordMap(null);
	};

	const mapImageUrl = (url, block) => {
	  if (url.startsWith('https://www.notion.so/image/')) {
	    const imageUrl = new URL(url);
	    const s3Url = imageUrl.searchParams.get('url');
	    return s3Url ? decodeURIComponent(s3Url) : url;
	  }
	  return url;
	};


	return(
	<div>
	    <Container style={{marginTop: '1.5rem', textAlign: 'left'}}>
	    <Row style={{textAlign: 'right', marginBottom: '5px'}}>
		<span style={{fontWeight: 'bold', fontSize: '0.7rem'}} onClick={()=>navigate('/notice')}> 더보기 </span>
	    </Row>
		<table cellPadding="9" cellSpacing="0" style={tableStyle}>
		    <thead>
			<tr style={{backgroundColor: '#eeeeee'}}>
			    <th style={{textAlign: 'center'}}> 공지제목 </th>
			    <th style={{textAlign: 'center'}}> 공지날짜 </th>
			</tr>
		    </thead>
		    <tbody>
			{notionData && notionData.map((page) => (
			    <tr onClick={()=>handleShowNotice(page.id)}>
				<td>{page.properties['이름'].title[0].plain_text}</td>
				<td style={{textAlign: 'right'}}>{page.properties['공지날짜'].date.start}</td>
			    </tr>
			))}
		    </tbody>
		</table>
	    </Container>
	    {recordMap && (
		<div style={contentDetailStyle}>
		    <Row>
		    <Col></Col>
		    <Col xs={2} onClick={()=>handleCloseNotice()}><X size={20}/></Col>
		    </Row>
		    <NotionRenderer style={{textAlign: 'left'}}
		    	recordMap={recordMap} 
		    	fullPage={true}
		        mapImageUrl={mapImageUrl}
		    />
		</div>
	    )}
	</div>
	);
}

export default SettingNotion;
