import React, { useState, useEffect } from 'react';
import axios from 'axios';


const SettingNoShowPhone = () => {
  const [isToggled, setIsToggled] = useState(false);
  const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
  const token = localStorage.getItem('token');


  useEffect(()=> {
	const fetchData = async() => {
	    const response = await axios.post(`${apiUrl}/CheckNoShowPhone`,{ myToken: token });
	    if(response.data.bPhone === true){
		    setIsToggled(true);
	    } else{
		    setIsToggled(false);
	    }
	};
	fetchData();
  },[]);

  const toggleSwitch = async() => {
	setIsToggled(!isToggled);
	const changeToggled = await axios.post(`${apiUrl}/CheckNoShowPhone`,{ myToken: token, change: true });
	if(changeToggled.data.state === 'success'){
		setIsToggled(!isToggled);
		console.log('bPhone success');
	}else{
		alert('오류가 발생하였습니다.');
	}
  };

  return (
  <div style={{textAlign: 'center', marginTop: '1.5rem'}}>
    <div style={containerStyle}>
      <div style={toggleContainerStyle} onClick={toggleSwitch}>
        <div style={{ ...toggleButtonStyle, backgroundColor: isToggled ? 'orange' : '#ccc' }}>
          <div
            style={{
              ...toggleCircleStyle,
              transform: isToggled ? 'translateX(26px)' : 'translateX(0)',
            }}
          />
        </div>
        <span style={titleStyle}>{isToggled ? '번호공개 켜짐' : '번호공개 꺼짐'}</span>
      </div>
      <p style={descriptionStyle}>
        {isToggled
          ? '번호공개 OFF 시 친구도 번호를 알 수 없어요.'
          : '번호공개 ON 시 친구만 번호를 알 수 있어요.'}
      </p>
    </div>
  </div>
  );
};

// 스타일 설정
const containerStyle = {
  display: 'inline-block',
  flexDirection: 'column',
  alignItems: 'center',
  fontFamily: 'Arial, sans-serif',
  backgroundColor: '#eaeaea',
  width: '90%',
  padding: '1rem',
  borderRadius: '1rem',
  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px',
};

const toggleContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  marginBottom: '10px',
};

const toggleButtonStyle = {
  width: '50px',
  height: '24px',
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  padding: '2px',
  transition: 'background-color 0.3s',
};

const toggleCircleStyle = {
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  backgroundColor: '#fff',
  transition: 'transform 0.3s',
};

const titleStyle = {
  marginLeft: '10px',
  fontWeight: 'bold',
  fontSize: '16px',
};

const descriptionStyle = {
  fontSize: '14px',
  textAlign: 'center',
  margin: 0,
  fontSize: '0.8rem',
};

export default SettingNoShowPhone;

