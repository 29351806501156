import {Container, Row, Col, Button, Image, Ratio} from 'react-bootstrap';
import {useState, useEffect} from 'react';
import {ChevronCompactDown, ChevronCompactUp} from 'react-bootstrap-icons';
import axios from 'axios';
import SettingMyProfile from './SettingMyProfile.js';

const nnameStyle={ fontSize: '1rem', fontWeight: 'bold'};
const messageStyle={paddingTop: '0.3rem'};

function SettingProfile(){

	const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
	const token = localStorage.getItem('token');

	//이미지 닉네임 메시지 세팅
	const [myUid, setMyUid] = useState('');
	const [myImage, setMyImage] = useState('');
	const [myNickName, setMyNickName] = useState('');
	const [myMessage, setMyMessage] = useState('');

	useEffect(() => {
	    const fetchData= async() =>{
		const response = await axios.post(`${apiUrl}/MyInfo`, { myToken: token });
		console.log('MyInfo : ',response.data);
		setMyUid(response.data.uid);
		setMyImage(response.data.pimage);
		setMyNickName(response.data.nname);
		setMyMessage(response.data.message);
	    };
	
	    fetchData();

	},[]);

	const [profileFixSet, setProfileFixSet] = useState(false);
	const profileFix = () => {
		setProfileFixSet(!profileFixSet);
	};

	return(
	    <Container style={{textAlign:'left', paddingTop: '20px'}}>
		<Row onClick={()=>profileFix()}>
		    <Col xs={3}>
			<Ratio aspectRatio="1x1">
			    <Image src={myImage} roundedCircle fluid />
			</Ratio>
		    </Col>
		    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
			<Row style={nnameStyle}> {myNickName} </Row>
			<Row style={messageStyle}> {myMessage} </Row>
		    </Col>
		    <Col xs={2} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
			{profileFixSet
			 ? <ChevronCompactUp size={20} />
			 : <ChevronCompactDown size={20} />}
		    </Col>
		</Row>
		{profileFixSet && (<SettingMyProfile uid={myUid}/>)}
		<hr />
	    </Container>
	);
}

export default SettingProfile;
