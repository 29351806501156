import {Row, Col, Button, Image,Ratio, Container, Modal} from 'react-bootstrap';
import { PhoneVibrateFill, ChatDotsFill, PatchMinusFill, PatchCheckFill, XCircleFill, ExclamationCircleFill} from 'react-bootstrap-icons';
import {useParams, useNavigate } from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import GoBack from '../GoBack.js';
import axios from 'axios';

const NickName ={
	paddingLeft: '10px',
	fontSize: '1.2rem',
	fontWeight: 'bold',
	height:'25%'
};

const MyMessage = {
	paddingLeft: '10px',
	fontSize: '0.9rem',
	marginBottom: '10px',
	minHeight: '25%'
};

const SetText = {
	textAlign: 'center',
	fontSize: '0.7rem',
	width: '100%',
	padding: 0,
	margin: 0,
	display: 'grid'
};
const SetImage = {
	width: '100%',
	height: '25px',
	color: '#ef9304',
}
const PreProfileText = {
	width: '100%',
	textAlign: 'center',
	margin:0,
	paddingLeft: '5px',
	display: 'inline-block'
};
const PreProfileImage = {
	padding: '5px',
	width: '95%',
	height: '95%',
};
const WideImageStyle = {
        zIndex: 1001,    
        position: 'fixed',
        backgroundColor: '#252424',
        width: '100%', 
        height: '100vh',
        top: 0, 
        left: 0,    
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
        padding: '0px', 
};
const WideBottomButtonStyle = {
        fontSize: '1.4rem',
        position:'fixed',
        zIndex: 1002,
        bottom:'20px',
        width:'100%',
        paddingLeft: '10%',
        paddingRight: '10%',
};
const BottomText = {
        fontSize: '0.8rem',
        color:'white'
};
const connectCheckStyle ={fontSize: '0.8rem', width: '100%'};

function FriendProfile() {
	const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
	const navigate = useNavigate();

	//이미지 보기
	const [showImage, setShowImage] = useState(false);
        const [wideImageSrc, setWideImageSrc] = useState(null);
	const wideImage = (imageSrc) => {
                    setShowImage(!showImage);
                    setWideImageSrc(imageSrc);
        }

	//이미지 저장할배열 생성
	const [imageInfo, setImageInfo] = useState([]);

	//ID 받아오기 Params.id
	const Params = useParams();
	const ProfileImage = imageInfo.map((v) => {
            // UNIX 타임스탬프를 JavaScript Date 객체로 변환
            const date = new Date(v.time);
        
            // 년, 월, 일 추출
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고, 두 자리로 포맷합니다.
            const day = String(date.getDate()).padStart(2, '0'); // 일을 두 자리로 포맷합니다.            
            // YYYY-MM-DD 형식으로 포맷
	    const formattedDate = `${year}-${month}-${day}`;

            return (
                <Col xs={4}>
                    <Row style={PreProfileText}>{formattedDate}</Row>
                     <Row>
                        <Ratio aspectRatio="1x1" style={PreProfileImage} onClick={() => wideImage(v.pimage)}>
                            <Image src={v.pimage} fluid />
                        </Ratio>
                    </Row>                
		</Col>
            );
	});


        const [userProfileInfo, setData] = useState(null);
	const [isFriend, setIsFriend] = useState(null);
	const [isFriendText, setIsFriendText] = useState(null);
	const [isBlock, setIsBlock] = useState(false);
        useEffect(()=>{
                const fetchData = async() => {
                        const token = localStorage.getItem('token');
                        const response = await axios.post(`${apiUrl}/FriendProfile`,{ myToken: token, fuid : Params.id }); 
			const checkBlock = await axios.post(`${apiUrl}/CheckBlock`,{ myToken: token, blockUid : Params.id });
                        await setData(response.data.userInfo);
			await setIsBlock(checkBlock.data.state);
			await setIsFriend(response.data.friendCheck);
			
		};  
                fetchData();
		console.log('useEffect');
		handleProfileImages();
        },[isFriend]);

	//친구끊기 등 클릭
	const [connectCheck, setConnectCheck] = useState(false);
	const handleConnectCheck = () => {
		setConnectCheck(!connectCheck);
	};

	//친구끊기 팝업
	const handleTextFunc = (TXT) => {
		switch(TXT){
			case 'FRIEND_USER':
				return "정말 친구관계를 끊겠습니까?";
			case 'REQ_FRIEND_USER':
				return "친구를 수락하시겠습니까?";
			case 'REQ_FRIEND_ME':
				return "친구요청을 취소하시겠습니까?";
			case 'UNKNOWN_USER':
				return "친구 요청을 보내시겠습니까?";
			defualt:
				return '확인중입니다';
		}
	};
	const handleConnectClick = async(isConFriend) =>{
		try {
			const token = localStorage.getItem('token');
			//수정필요
			const response = await axios.post(`${apiUrl}/ConnectFriend`, { myToken: token, fuid : Params.id});
			setIsFriend(response.data);
			setConnectCheck(!connectCheck);
		} catch (error) {
			console.error('ConnectFriend 요청 중 오류 발생: ', error);
		}
	};

	//프로필 이미지 받아오기
	const handleProfileImages = async() => {
		console.log('handleProfileImages');
		try {
			const token = localStorage.getItem('token');
			const response = await axios.post(`${apiUrl}/ProfileImages`, { myToken: token, fuid : Params.id});
			setImageInfo(response.data.profileImages);
		}catch (error) {
			console.error('ConnectFriend 요청 중 오류 발생: ', error);
		}
	}

	//// 추가: Modal 상태 관리
	const [showChatModal, setShowChatModal] = useState(false);

	//Modal 을 닫는 함수
	const handleCloseChatModal = () => setShowChatModal(false);

	// Modal을 여는 함수
	const handleShowChatModal = () => setShowChatModal(true);

	const friendChatStart  = async(fuid) => {
		
		const token = localStorage.getItem('token');
		const response = await axios.post(`${apiUrl}/friendChatStart`, { myToken: token, fuid, fuid});

		if(response.status === 200 && response.data.message === 'noFriend'){
			handleShowChatModal();
		}else{
			console.log('friendChatStart : ',response.data);
			navigate('/chatRoomNormal/' + response.data.rid);
		}
		
	};

	const [blockShow, setBlockShow] = useState(false);
	const [blockText, setBlockTest] = useState('');
	const handleBlockUser = async(blockUid, state) => {
		if(state){
		    const token = localStorage.getItem('token');
		    const response = await axios.post(`${apiUrl}/BlockUser`, { myToken: token, blockUid: blockUid});
		    if(response.data.state){
			    setBlockShow(false);
			    setIsBlock(response.data.blockState);
			    setIsFriend('UNKNOWN_USER');
			    alert(response.data.message);
		    }else{
			    setBlockShow(false);
			    setIsBlock(response.data.blockState);
			    alert('오류가 발생하였습니다.');
		    }

		}else{
		    //Modal 보이는 부분  
		    setBlockShow(true);
		}
	};

	const handleCloseBlockModal = () => setBlockShow(false);

	return (
	<div style={{padding: '10px', paddingTop: '20px'}}>
		<Modal show={blockShow} onHide={handleCloseBlockModal} centered>
			<Modal.Body style={{textAlign:'center', marginTop: '1rem', fontSize: '0.9rem'}}>
			{isBlock ?(
				<>
					차단을 푸시겠습니까?
					<p style={{ color: 'red', margin: 0, marginTop: '1rem' }}> 차단을 해제하고 친구를 맺을 수 있어요</p>
				</>
			) : (
				<>
			   		 사용자를 차단/ 또는 해제 하시겠습니까?<br />
			    		<p style={{color: 'red', margin: 0, marginTop: '1rem'}}> 차단할 경우 친구관계가 끊겨요</p>
			    		<p style={{color: 'red', margin: 0}}> 차단을 해제하고 친구를 맺을 수 있어요</p>
				</>
			)}
			</Modal.Body>
				<Row style={{marginBottom: '2rem'}}>
					<Col></Col>
					<Col>
					    <Button variant="light" style={connectCheckStyle} onClick={()=>handleBlockUser(Params.id, true)}>
						확인
					    </Button>
					</Col>
					<Col>
					    <Button variant="secondary" onClick={handleCloseBlockModal} style={connectCheckStyle}>
						닫기
					    </Button>
					</Col>
					<Col></Col>
				</Row>
		</Modal>

		<Modal show={showChatModal} onHide={handleCloseChatModal} centered>
			<Modal.Body style={{textAlign:'center', marginTop: '1rem', fontSize: '0.9rem'}}>친구랑만 채팅을 할 수 있어요</Modal.Body>
				<Row style={{marginBottom: '2rem'}}>
					<Col></Col>
					<Col>
					<Button variant="secondary" onClick={handleCloseChatModal} style={connectCheckStyle}>
						닫기
					</Button>
					</Col>
					<Col></Col>
				</Row>
		</Modal>
		{connectCheck && (
			<div>
			    <Modal show={connectCheck} centered>
				<Modal.Body style={{textAlign:'center', marginTop: '1rem'}}>{handleTextFunc(isFriend)}</Modal.Body>
				<Row style={{marginBottom: '2rem'}}>
				    <Col></Col>
				    <Col>
					<Button variant="secondary" onClick={() => handleConnectClick(isFriend)} style={connectCheckStyle}>
				    	네
					</Button>
				    </Col>
				    <Col>
					<Button variant="warning" onClick={handleConnectCheck} style={connectCheckStyle}>
				    	아니오
					</Button>
				    </Col>
				    <Col></Col>
				</Row>
			    </Modal>
			</div>
		)}
                 {showImage && (
                        <div className="overlay" style={WideImageStyle}>
                            <img src={wideImageSrc} alt="Wide Image" style={{width:'100%', marginTop: '-25%',  backgroundColor: 'white'}} />
                                <div className="close-btn" style={WideBottomButtonStyle}>
                                    <div>
			 		<Button variant="light" style={{ position: 'fixed', top: '10px', right: '10px', fontSize: '0.7rem', fontWeight: 'bold'}} onClick={wideImage}>
			 			닫기
			 		</Button>
                                    </div>
                                </div>
                         </div>
            )}
	    <GoBack />
	    <h3><b>프로필 화면</b></h3>
		<Container style={{textAlign: 'left'}}>
		{userProfileInfo &&(
	   	    <Row>
	      		<Col xs={4} className={""} style={{padding: "0"}}>
			    <Ratio aspectRatio="1x1" onClick={() => wideImage(userProfileInfo.pimage)}>
				<Image src={userProfileInfo.pimage} style={{padding:0}}thumbnail fluid />
			    </Ratio>
			</Col>
	      		<Col xs={8} style={{paddingLeft: '20px'}}>
			    <Row style={NickName}>{userProfileInfo.nname}</Row>
			    <Row style={MyMessage}>{userProfileInfo.message}</Row>
			    <Row>
		    		<Col>
				    <Row>
					{isFriend === 'FRIEND_USER' ? (
						<PhoneVibrateFill
						    style={{ 
							    ...SetImage, 
							    cursor: userProfileInfo.phone ?  'pointer' : 'defulat',
							    color: userProfileInfo.phone ? '#ef9304' : 'rgb(255 155 0 / 35%)'
						    }}
						    onClick={() => {
							    console.log('userProfileInfo: ', userProfileInfo);
							    if (userProfileInfo.phone) {
							        window.location.href = `tel:${userProfileInfo.phone}`
							    }
						    }}
						/>
					) : (
						<PhoneVibrateFill style={{...SetImage, color: 'rgb(255 155 0 / 35%)'}} />
					)}
				    </Row>
				    <Row style={SetText}>
					전화
				    </Row>
				</Col>
		    		<Col>
				    <div onClick={() => friendChatStart(Params.id)}>
				    <Row>
				        <ChatDotsFill style={SetImage}/>
				    </Row>
				    <Row style={SetText}>
					채팅
				    </Row>
				    </div>
				</Col>
		    		<Col>
				    <Row>
  {isFriend === 'FRIEND_USER' && (
    <>
      <PatchMinusFill style={isBlock ? { ...SetImage, color: 'rgb(255 155 0 / 35%)' } : SetImage} /> {/* 친구끊기 아이콘 */}
      <span style={SetText} onClick={isBlock ? null : () => handleConnectCheck()}>친구끊기</span>
    </>
  )}
  {isFriend === 'REQ_FRIEND_USER' && (
    <>
      <PatchCheckFill style={isBlock ? { ...SetImage, color: 'rgb(255 155 0 / 35%)' } : SetImage} /> {/* 친구수락 아이콘 */}
      <span style={SetText} onClick={isBlock ? null : () => handleConnectCheck()}>친구수락</span>
    </>
  )}
  {isFriend === 'REQ_FRIEND_ME' && (
    <>
      <PatchMinusFill style={isBlock ? { ...SetImage, color: 'rgb(255 155 0 / 35%)' } : SetImage} /> {/* 요청취소 아이콘 */}
      <span style={SetText} onClick={isBlock ? null : () => handleConnectCheck()}>요청취소</span>
    </>
  )}
  {isFriend === 'UNKNOWN_USER' && (
    <>
      <PatchCheckFill style={isBlock ? { ...SetImage, color: 'rgb(255 155 0 / 35%)' } : SetImage}  /> {/* 친구요청 아이콘 */}
      <span style={SetText} onClick={isBlock ? null : () => handleConnectCheck()}>친구요청</span>
    </>
  )}
  {isFriend === 'BLOCK_USER' && (
    <>
      <PatchCheckFill style={isBlock ? { ...SetImage, color: 'rgb(255 155 0 / 35%)' } : SetImage}  /> {/* 친구요청 아이콘 */}
      <span style={SetText} onClick={isBlock ? null : () => handleConnectCheck()}>친구요청</span>
    </>
  )}
				    </Row>
				</Col>
				<Col onClick={() => handleBlockUser(Params.id)}>
				    <Row>
					<ExclamationCircleFill style={isBlock ? { ...SetImage, color: 'red' } : SetImage} />
				    </Row>
				    <Row style={SetText}>
					차단
				    </Row>
				</Col>
			    </Row>
	      		</Col>
	    	    </Row>
		)}
	  	    <div className="text-center">
			<Row style={{marginTop:'10px', marginBottom: '10px'}}>
		    	    <Col>
			    <hr style={{paddingLeft: '35%', paddingRight: '35%', display:"inline-block"}}/>
		    	    </Col>
		    	    <Col>이전프로필</Col>
		    	    <Col>
				<hr style={{paddingLeft: '35%', paddingRight: '35%', display:"inline-block"}}/>
		    	    </Col>
			</Row>
			<Row>
			    {ProfileImage.length > 0 ? ProfileImage : (<Col><br/><br />이전 프로필이 없습니다.</Col>)}
			</Row>
	  	    </div>
		</Container>
	</div>
	);
}

export default FriendProfile;
