import {Row, Col, Container, Image, Ratio} from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
import Slider from 'react-slick';
import {Link} from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';

const containerStyle={
        textAlign: 'left',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        borderRadius: '1rem',
};
const imageStyle={
        backgroundColor: 'white',
        borderRadius: '1rem',
};



function FriendRequest() {

	const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
	//좌우 슬라이드
	const settings = {
		infinite: false,
		adaptiveHeight: true,
		speed: 500,
		draggable: false,
		dots: false,
		slidesToShow: 6,
		slidesToScroll: 6,
		swipeToSlide: false,
		textAlign:'left',
		prevArrow: <></>,
		nextArrow: <></>
	};


	const [userFriendReqList, setData] = useState(null);
        useEffect(()=>{
		const token = localStorage.getItem('token');
		const fetchData = async() => {
			const response = await axios.post(`${apiUrl}/FriendRequest`, {myToken: token});
			await setData(response.data);
			await console.log(response.data);
		};
		fetchData();
        },[]);

	let ViewRequestUserList = null;
	let numberOfRequestUserList = 0;

	//친구 신청 총 인원 표현
	if(userFriendReqList) {
	ViewRequestUserList = userFriendReqList.map((v) => (
		<Col xs={2} style={{padding: '0', marginRight: '10px'}}>
		<Link to={'/friendProfile/' + v.uid} className='justify-content-center align-items-center' style={{display:'contents',textDecoration:'none', color:"black"}}>
   		    <Ratio aspectRatio="1x1" style={imageStyle}>
			<Image src={v.pimage} roundedCircle fluid />
		    </Ratio>
		    <div style={{textAlign:'center', width:'100%', overflow: 'hidden', textOverflow : 'ellipsis'}}> {v.nname} </div>
		</Link>
		</Col>
	));
		//총 길이
		numberOfRequestUserList = userFriendReqList.length;
	}


	return (
	<div style={{padding: "10px", marginTop: '20px'}}>
		<h3><b>친구신청</b><span style={{fontSize: 'calc(0.9rem + 0.1vw)', marginLeft: '5px'}}> {numberOfRequestUserList}명</span></h3>
            <Container fluid="md" style={{...containerStyle, textAlign: 'left'}}>
              <Row>
		<Slider {...settings}>
		{ViewRequestUserList}
		{Array.from({ length: 6 - numberOfRequestUserList }).map((_, index) => (
            		<Col key={index}></Col>
		))}
		</Slider>
              </Row>
            </Container>
	</div>
	);
}

export default FriendRequest;
