import {Row, Col, Image, Ratio} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {useState, useEffect} from 'react'; 
import axios from 'axios';

const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
const token = localStorage.getItem('token');

//style 모음
const timeStyle={
	fontSize: '0.6rem',
	margin: 0,
	textAlign: 'right'
};
const ChatRoomName = {
	padding: 0,
	margin: 0,
	fontWeight: 'bold'
};
const displayDot = {
	overflow: 'hidden', 
	textOverflow : 'ellipsis', 
	whiteSpace: 'nowrap',
	display: 'block',
	color: '#787878'
};
const containerStyle={
        textAlign: 'left',
        paddingTop: '0.1rem',
        paddingBottom: '1rem',
        borderRadius: '1rem',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        backgroundColor: '#ecd8fe',
};
const imageStyle={
        backgroundColor: 'white',
        borderRadius: '1rem',
};

const noDataStyle = {width:'100%', textAlign:'center', padding: '1rem'};

//시간 변경하는 함수
const getTimeAgo = (timestamp) => {
  const now = new Date();
  const then = new Date(timestamp);
  const diff = Math.floor((now - then) / 1000); // difference in seconds
  if(timestamp){
      if (diff < 60) {
        return '방금 전';
      } else if (diff < 3600) {
        return `${Math.floor(diff / 60)}분 전`;
      } else if (diff < 86400) {
        return `${Math.floor(diff / 3600)}시간 전`;
      } else if (diff < 604800) {
        return `${Math.floor(diff / 86400)}일 전`;
      } else {
        return '오래 전';
      }   
  }else{
      return 'ㅡ';
  }
};



function ChatMyLocation() {

	const token = localStorage.getItem('token');
	const [roomInfo, setRoomInfo] = useState([]);
	const [countResults, setCountResults] = useState([]);
	useEffect(() => {
	    
	    const roomInfoListFunc = async () => {
		const response = await axios.post(`${apiUrl}/ChatRoomListNormal`, {myToken: token});
		console.log('ChatRoomListNormal : ', response.data);
		setRoomInfo(response.data.chatRoomList);
		setCountResults(response.data.countResults);
	    };
	  
	    roomInfoListFunc();

	},[]);

	const myChatRoomList = roomInfo.map((v) => {
		const countResult = countResults?.find(result => result.rid === v.rid);
		const count = countResult ? countResult.count : 0; // 해당 항목이 없으면 기본값 0

		return (
			<div>
			<Link style={{textDecoration: 'none', color: 'black'}} to={'/chatRoomNormal/'+v.rid}>
			    <Row style={{marginTop:'18px'}}>
				<Col xs={2}>
				    <Ratio aspectRatio='1x1'>
					<Image src={v.userInfo.pimage} fluid rounded />
				    </Ratio>
				</Col>
				<Col xs={8}>
				    <Row>
					<span style={{padding: 0}}>{<span style={ChatRoomName}>{v.userInfo.nname}</span>}</span>
				    </Row>
				    <Row style={displayDot}>
					{v.lastMessage[0] &&
					    (v.lastMessage[0].startsWith("https://innerong-profile-image") &&
					    (v.lastMessage[0].endsWith(".jpg") || v.lastMessage[0].endsWith(".jpeg") || v.lastMessage[0].endsWith(".png") || v.lastMessage[0].endsWith(".gif"))
					    ? "이미지"
					    : v.lastMessage[0])}
				    </Row>
				</Col>
				<Col xs={2} style={{...timeStyle, position: 'relative'}}>
				    {count !== 0 && (
				    <span style={{position: 'absolute', top: '-1.1rem', right: '1rem', backgroundColor: '#ffdf00', padding: '2px 5px', borderRadius: '1rem', fontWeight: 'bold'}}>
					    {count}
				    </span>
				    )}
				    <span>
					{getTimeAgo(v.lastDate)}
				    </span>
				</Col>
			    </Row>
			</Link>
			</div>
		);
	});

	return(
		<div style={{textAlign:'left', marginTop: '1rem', paddingBottom: '1rem', paddingRight: '10px', paddingLeft: '10px'}}>
		    <Row>
		    <h3 style={{marginBottom:0}}><b>채팅방</b></h3>
		    </Row>
		    <div style={{...containerStyle, backgroundColor: 'white', paddingLeft: '5px', paddingRight: '5px', marginTop: '1rem'}}>
			{myChatRoomList.length > 0 ? (myChatRoomList) : (
	                            <div style={{paddingTop: '1rem', textAlign: 'center'}}>
	                            <Image src='/chatZero.png' style={{...imageStyle, maxWidth: '10%'}} />
	                                <div style={noDataStyle}>
	                                    채팅을 시작해보세요
	                                </div>
	                            </div>
                        )}
		    </div>

		<br /><br /><br /><br /></div>
	);
}

export default ChatMyLocation;
