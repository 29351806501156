import {useParams} from 'react-router-dom';

function LocationForm(){
	const Params = useParams();
	return (
		<>
		{Params.num}
		</>

	);
}

export default LocationForm;
