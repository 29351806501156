import {Row, Col, Button, Image, Ratio, Container, Modal, Form} from 'react-bootstrap';
import {useState, useEffect} from 'react';
import axios from 'axios';
import {useParams, useNavigate} from 'react-router-dom';

const rowStyle = {marginTop: '0.3rem', marginBottom: '0.3rem'};
const titleStyle = {fontWeight: 'bold'};
const contentStyle = {textAlign: 'right'};
const connectCheckStyle ={fontSize: '0.8rem', width: '100%'};
const secessionAgreeStyle = {padding: '0 1rem 1rem 1rem', fontSize: '0.8rem'};

const formatDate = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

function SettingSecession() {

    const Params = useParams();
    const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
    const token = localStorage.getItem('token');



    //데이터 세팅
    const [myUid, setMyUid] = useState('');

    useEffect(() => {
	const fetchData = async() => {
	    const response = await axios.post(`${apiUrl}/SettingMyProfile`, { myToken: token });

	    setMyUid(response.data.uid);
	};

	fetchData();

    },[]);

    const [secessionSet, setSecessionSet] = useState(false);
    const handleSecession = () => {
	setSecessionSet(!secessionSet);
    };


    const [checkedItems, setCheckedItems] = useState(false);
    const handleCheckboxChange = (event) => {
        setCheckedItems(event.target.checked);
    };
 
    const navigate = useNavigate();
    const [textAreaValue, setTextAreaValue] = useState('');
    const handleChange = (event) => {
	setTextAreaValue(event.target.value);
    };
    const [textAreaSet, setTextAreaSet] = useState(false);
    const handleSecessionClick = async(uid) => {
	console.log(checkedItems);
	if(checkedItems){
	    const response = await axios.post(`${apiUrl}/SecessionAccount`, { myToken: token, whySecession: textAreaValue });
	    console.log(response);
	    setSecessionSet(!secessionSet);
	    navigate('/login');
	}else{
	    setTextAreaSet(true);
	}
    };

    const [logoutBtn, setLogoutBtn] = useState(false);
    const handleLogoutSet = () => {
	setLogoutBtn(!logoutBtn);
	console.log('logoutBtn : ', logoutBtn);
    };

    const handleLogoutClick = () => {
	localStorage.removeItem('token');
	navigate('/login');
	console.log('handleLogoutClick');
    };


    return(
	<Container style={{marginTop: '2.5rem'}}>
	    <Row style={{textAlign:'right'}}>
	        <Col style={{textAlign:'right'}}>
	    	    <span style={{color:'gray', fontSize: '0.7rem'}} onClick={()=>handleSecession()}>탈퇴하기</span>
	        </Col>
	    </Row>

	    {secessionSet && (
                <div>
                    <Modal show={secessionSet} centered>
                        <Modal.Body style={{textAlign:'center', marginTop: '1rem', fontWeight: 'bold'}}>탈퇴하시려는 이유를 공유해주세요.</Modal.Body>
		    	    <div style={{padding: '1rem'}}>
		    	        <Form.Control as='textarea' value={textAreaValue} onChange={handleChange} style={{fontSize: '0.7rem', marginBottom: '1rem'}} />
		    	    </div>
		    	    <div style={secessionAgreeStyle}>
		    		<label>
		    		    <input type='checkbox' name='item1' style={{marginRight: '0.5rem'}} checked={checkedItems} onChange={handleCheckboxChange} />
		    		    정말로 탈퇴하시겠습니까?
		    		</label>
		    		{textAreaSet && ( <div style={{color: 'red', textAlign: 'center', fontSize: '0.7rem'}}> 탈퇴에 동의하셔야 계정이 삭제됩니다. </div> )}
		    	    </div>
		    	    <Row style={{marginBottom: '2rem'}}>
                                <Col></Col>
                                    <Col>
                                        <Button variant="secondary" onClick={() => handleSecessionClick(myUid)} style={connectCheckStyle}>
                                        네
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button variant="warning" onClick={handleSecession} style={connectCheckStyle}>
                                        아니오
                                        </Button>
                                    </Col>
                                <Col></Col>
                            </Row>
                     </Modal>
                 </div>
	    )}
	</Container>
    );

}

export default SettingSecession;
