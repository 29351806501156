import { Navbar, Row, Col } from 'react-bootstrap';
import {PeopleFill, PinMapFill, ChatSquareDotsFill, GearFill} from 'react-bootstrap-icons';
import { useLocation, Link } from 'react-router-dom';
import { isEqual } from 'lodash';

const footerIconActive = {
	width: '100%', 
	//color: '#FF914D',
	color: '#f1ca0e',
	backgroundColor: 'white',
	borderRadius: '1rem',
	paddingTop: '0.2rem',
	paddingBottom: '0.2rem'
};
const footerIconStandby = {
	width: '100%',
	color: '#cbcaca',
	paddingTop: '0.2rem',
	paddingBottom: '0.2rem',
};
const footerRow = {
	width: '100%',
	maxWidth: '720px',
	minWidth: '200px',
	height: '30px'
};

function NavBarFooter() {
	//URI 찾기
	const nowUri = useLocation();
	const nowPath = nowUri.pathname.split('/')[1];;

	return(
	  <Navbar fixed="bottom" variant="dark" className="text-center justify-content-center" 
		style={{padding: "13px 0", borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', backgroundColor: '#f7f7f7', boxShadow: 'rgba(0, 0, 0, 0.2) 2px -2px 20px'}}>
	    <Row style={footerRow}>
	      <Col>
		<Link to="/friend">
		<PeopleFill style={isEqual(nowPath, 'friend')?footerIconActive:footerIconStandby} size={30}/>
		</Link>
	     </Col>
	      <Col>
		<Link to="/location">
		<PinMapFill style={isEqual(nowPath, 'location')?footerIconActive:footerIconStandby} size={30}/>
		</Link>
	      </Col>
	      <Col>
		<Link to="/chatting">
		<ChatSquareDotsFill style={isEqual(nowPath,'chatting')?footerIconActive:footerIconStandby} size={30}/>
		</Link>
	      </Col>
	      <Col>
		<Link to="/setting">
		<GearFill style={(nowPath === 'setting' || nowPath === 'notice')?footerIconActive:footerIconStandby} size={30}/>
		</Link>
	      </Col>
	    </Row>
	  </Navbar>
	);
}

export default NavBarFooter;
