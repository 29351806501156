

function ChatInsertMenu(){


    return(
	<>
		HIHI
	</>
    );
}

export default ChatInsertMenu;
