import './App.css';
import { Route, Routes,  useNavigate, useLocation  } from "react-router-dom";
import { useEffect, useState } from 'react';
import axios from 'axios';
import FriendMain from './component/friend/FriendMain.js';
import FriendProfile from './component/friend/FriendProfile.js';
import FixMyProfile from './component/friend/FixMyProfile.js';
import ProfileFix from './component/friend/ProfileFix.js';
import BlockUsers from './component/friend/BlockUsers.js';
import LocationMain from './component/location/LocationMain.js';
import MyClassAll from './component/location/MyClassAll.js';
import LocationClassAll from './component/location/LocationClassAll.js';
import OftenLocationClassAll from './component/location/OftenLocationClassAll.js';
import LocationForm from './component/location/LocationForm.js';
import ChatMain from './component/chatting/ChatMain.js';
import ChatRoom from './component/chatting/ChatRoom.js';
import ChatRoomNormal from './component/chatting/ChatRoomNormal.js';
import NavBarFooter from './component/NavBarFooter.js';
import SettingMain from './component/setting/SettingMain.js';
import SettingNoticeAll from './component/setting/SettingNoticeAll.js';
import LoginMain from './component/login/LoginMain.js';

function LoginJWT() {

    const navigate = useNavigate();
    const location = useLocation(); // 현재 경로를 확인하기 위한 훅
    const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;

    useEffect(()=> {
	const fetchToken = async() => {
		try{
		    const token = localStorage.getItem('token');
		    if(!token && location.pathname !== '/login'){
			    navigate('/login');
		    }else{
		        const response = await axios.post(`${apiUrl}/TokenCheck`, {token: token});
		        if (response.data.res === 'N' && location.pathname !== '/login') {
			     localStorage.removeItem('token'); // 로컬 스토리지에서 토큰 제거
		             navigate('/login');
		        }
		    }
		} catch (error){
			console.log(error);
		}
	};
	fetchToken();
    },[navigate]);
	return null;
}

function App() {

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(false);

	},[]);

	if(loading){
		return(
			<div className="spinner-container">
			<div className="spinner"></div>
			</div>
		);;
	}


	return (
  <div>
       <div className="App" style={{ display: 'flex', flexDirection: 'column'}}>
	<LoginJWT />
	<Routes>
		<Route path="/login" element={<><LoginMain /></>} />
	        <Route path="/friend" element={<><FriendMain /><NavBarFooter /></>} />
		<Route path="/friendProfile/:id" element={<><FriendProfile /></>} />
	  	<Route path="/profile" element={<><FixMyProfile /></>} />
	  	<Route path="/profileFix" element={<><ProfileFix /></>} />
		<Route path="/blockUsers" element={<><BlockUsers /></>} />
		<Route path="/location" element={<><LocationMain /><NavBarFooter /></>} />
		<Route path="/location/:lo" element={<><LocationMain /><NavBarFooter /></>} />
	  	<Route path="/myClass" element={<><MyClassAll /> <NavBarFooter /></>} />
		<Route path='/myLoClass' element={<><LocationClassAll /><NavBarFooter /></>} />
	  	<Route path='/classForm/:num' element={<><LocationForm /></>} />
	  	<Route path='/myOftenLoClass' element={<><OftenLocationClassAll /><NavBarFooter /></>} />
	  	<Route path="/chatting" element={<><ChatMain /><NavBarFooter /></>} />
	  	<Route path="/chatRoom/:lo" element={<><ChatRoom /></>} />
		<Route path="/chatRoomNormal/:lo" element={<><ChatRoomNormal /></>} />
	  	<Route path="/setting" element={<><SettingMain /><NavBarFooter /></>} />
		<Route path="/notice" element={<><SettingNoticeAll /><NavBarFooter /></>} />
	      </Routes>
      </div>
  </div>
  );
}

export default App;
