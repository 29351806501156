import React, { useState } from 'react';
import LoginAgree from './LoginAgree.js';
import LoginAuth from './LoginAuth.js';

function LoginMain() {

  localStorage.removeItem('token'); // 로컬 스토리지에서 토큰 제거

  // LoginAgree에서 "다음" 버튼 클릭 시 호출될 함수
  const [nextClick, setNextClick] = useState(false);
  const handleNextClick = () => {
    console.log('LoginAuth 다음 클릭'); // LoginAuth 페이지로 이동
    setNextClick(!nextClick);
  };

  return (
    <div style={{padding: '15px', backgroundColor: 'white'}}>
      {nextClick ?
          <LoginAuth onNextClick={handleNextClick} /> :
          <LoginAgree onNextClick={handleNextClick} />
      }
    </div>
  );
}

export default LoginMain;
