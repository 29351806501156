import {Row, Col, Button, Image, Ratio, Form} from 'react-bootstrap';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import {useParams, Link} from 'react-router-dom';
import {SendFill, Bell, FileEarmarkImage, DashCircleFill} from 'react-bootstrap-icons';
import GoBack from '../GoBack.js';
import {io} from 'socket.io-client';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import ChatMenu from './ChatMenu.js';
import { Helmet } from 'react-helmet';
import ReportMessage from './ReportMessage.js';
import ClassView from '../location/ClassView.js';
import ChatInsertMenu from './ChatInsertMenu.js';
import { CSSTransition } from 'react-transition-group';

const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;

const ChatArea={ bottom: 0, left: '12px', paddingBottom: '10px', width:"100%", position: 'fixed', backgroundColor: 'white'};
const FormArea={ width: '100%', height: '1rem', maxHeight:'120px', fontSize: '0.8rem'};
const SendButton={ padding: '3px', paddingRight: '8px', paddingLeft: '8px', backgroundColor: 'orange', border: 0};
const SendArea={ marginRight: '3px'};
const timeArea={fontSize: '0.6rem'};
const subArea={display:'inline-block', marginTop: 'auto'};
const nickNameStyle={fontSize: '0.7rem', paddingLeft: '13px', fontWeight: 'bold', color: '#605d5d'};
const messageArea1={backgroundColor: '#efeeea', padding: '5px', paddingLeft: '10px', paddingRight: '10px',  marginRight:'8px', borderRadius: '5px', display:'inline-block', maxWidth: '85%'};
const messageArea2={textAlign: 'left',backgroundColor: '#ffd150', padding: '5px', paddingLeft: '10px', paddingRight: '10px',  marginLeft:'8px', borderRadius: '5px', display:'inline-block', maxWidth: '85%'};
const scrollContainerStyle = {height: '100vh', width: '100%', overflowY: 'scroll', overflowX: 'hidden', msOverflowStyle: 'none',  scrollbarWidth: 'none', paddingTop: '35px', paddingBottom: '35px', msOverflowStyle: 'none', scrollbarWidth: 'none', paddingLeft: '10px', paddingRight: '10px', backgroundColor: 'white'};
const scrollContainerInnerStyle = {display: 'none'};
const messageStyle = { wordWrap: 'break-word',whiteSpace: 'pre-wrap', maxWidth:'70%', fontSize: '0.8rem'};
const imageStyle = { wordWrap: 'break-word',whiteSpace: 'pre-wrap', maxWidth:'55%', fontSize: '0.8rem', maxHeight: '15rem'};
const changeDate = {width: '100%', textAlign: 'left', fontSize: '0.7rem', margin: '1rem 0', textWeight:'bold'};
const changeDateSub1 = {backgroundColor: '#efefef', textAlign:'center', padding: '0.2rem', fontWeight:'bold', borderRadius: '6px', margin: '0.5rem 0'};
const classAllMy = {textAlign: 'center', marginRight: '11px', marginLeft:'15px', width:'7rem', maxWidth:'400px', display: 'inline-flex'};
const classAllAthor = {textAlign: 'center', marginRight: '18px', marginLeft:'5px', width:'7rem', maxWidth:'400px', display: 'inline-flex'};
const fontSmall = {fontSize:'0.6rem', padding: '3px'};
const marginTop2 = {marginTop: '2px'};
const classBody = {backgroundColor: '#efefef', marginBottom:'2px', borderRadius: '6px',fontSize:'0.6rem', marginLeft: '0', paddingLeft: '4px', paddingRight: '4px', border: '0'};
const buttonSpace0 = {margin: '0', padding: '0',fontSize: '0.6rem', width: '100%'};
const fontSizeSmall2={fontSize: '0.6rem', width: '100%', marginRight:'0', backgroundColor: '#efefef', color:'black', fontWeight:'bold', border: '0'};
const divArea = {pading: '5px', display: 'inline-block', width: '60%', backgroundColor: '#fff5e3', borderRadius: '6px',padding: '3px 0'};
const cAreaSub0 = {backgroundColor: 'white', height: '5rem', minHeight: '30px', padding:'1rem', borderTop: '1px solid gainsboro', backgroundColor: '#fcfcfc'};
const cAreaSub1 = {width: '100%', textAlign:'center', margin:0, display:'block', height: '2rem'};
const cAreaSub2 = {width: '100%', textAlign: 'center', margin:0, display: 'block', fontSize: '0.7rem', fontWeight:'bold'};
const cAreaImage = {width: '80%', height:'90%'};
const previewBoxStyle = {position: 'fixed', bottom: '60px', left: '50%', transform: 'translateX(-50%)', padding: '10px', backgroundColor: 'rgba(0, 0, 0, 0.7)', color: 'white', borderRadius: '8px', cursor: 'pointer', zIndex: 1000};
const previewMessageStyle = {fontSize: '0.8rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '300px'};
const fullScreenImageStyle = {
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.9)',
  position: 'fixed',
  zIndex: 100,
  top: 0,
  left: 0
};


function checkType(MyType){
        let category = 0;
        switch(MyType) {
                case 'Amity': category = "/ClassAmity.png"; break;
                case 'Exercise': category = "/ClassExercise.png"; break;
                case 'Hobby': category = "/ClassHobby.png"; break;
                case 'Etc': category = "/ClassEtc.png"; break;
                default: category = "/ClassEtc.png"; break;
        }
        return category;
}


function ChatRoomNumSet(molecule, denominator){
	let chatSetNum = '';

	if(denominator == 0){
	    chatSetNum = denominator == 1? "": molecule;
	}else{
	    chatSetNum = denominator == 1? "": molecule+'/'+denominator;
	}
	return chatSetNum;
}

function ChatRoom(props) {

	const tempRoomNum = useParams();
	//임시 룸 데이터

	//Message STORE AREA
	const token = localStorage.getItem('token');
	const currentUserId = jwtDecode(token).loginUser.uid;
	const [message, setMessage] = useState('');
	const [messages, setMessages] = useState([]);
	const [roomUserNum, setRoomUserNum] = useState('0');
	const [roomInfo, setRoomInfo] = useState([]);
//////////////////////////////////////////////////////////////////////////////
	const [RoomNum, setRoomNum] = useState(tempRoomNum.lo);
	const [page, setPage] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const scrollContainerRef = useRef(null); // scroll-container 의 크기
	const messageEndRef = useRef(null); // scroll-container 의 맨 마지막
	const [newMessage, setNewMessage] = useState(null); // 새로운 메시지 미리보기 상태
	const [showImagePreview, setShowImagePreview] = useState(false); // 이미지 프리뷰 상태
	const [previewImage, setPreviewImage] = useState(null); // 프리뷰 이미지
	const [blockList, setBlockList] = useState([]); // Block User List 추출
	const socketRef = useRef(null);

	const fetchMessages = useCallback(async (rid, page) => {
		//4번째 실행
	    try {

		const response = await axios.post(`${apiUrl}/fetchMessages`, {token, rid, page });
		if (response.data.length > 0) {
		    const reversedData = response.data.reverse();
		    setMessages(prevMessages => [...reversedData, ...prevMessages]);
			//5번째 실행
		} else {
		    setHasMore(false);
		}
	    } catch (error) {
		console.error('Error fetching messages:', error);
	    }
	}, []);

	//////////////////////////////////////////////////////////////////////////////

	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const fetchData = async () => {
	    console.log('@@@fetcData 시작');
	    await fetchMessages(RoomNum, page);
	    await setTimeout(() => {
		const container = scrollContainerRef.current;
		    //alert('fetchData');
		if (container && container.scrollHeight - container.clientHeight < 10 && hasMore) {
		    console.log('@@@fetchData > if 문 안쪽 시작');
		    fetchMessages(RoomNum, page + 1);
		    setPage((prevPage) => prevPage + 1);
		    container.scrollTop = container.scrollHeight; //스크롤 위치 갱신
		    //alert('fetchData If In: ',container.scrollHeight);
		    
	    	}
		if(isInitialLoad){
		    setIsInitialLoad(false);
		    scrollToBottom();
		}
	    },100);
	};

useEffect(() => {
    // 소켓이 아직 초기화되지 않았을 때만 연결 설정
    if (!socketRef.current) {
	    console.log('@socket On');
        socketRef.current = io(apiUrl);
        
        // 소켓 이벤트 설정
        socketRef.current.emit('join room', RoomNum);

        socketRef.current.on('connect_error', (err) => {
            console.error('Connection error: ', err);
        });

        socketRef.current.on('new message', (newMsg) => {
            if (!blockList || !blockList.includes(newMsg.senderId)) {
		console.log('newMsg: ', newMsg);
                setMessages((prevMessages) => [...prevMessages, newMsg]);
                setNewMessage(newMsg); // 새로운 메시지 설정
                updateLastMessages(newMsg);
            }
        });
    }

    // 컴포넌트 언마운트 시 소켓 해제 및 이벤트 제거
    return () => {
        if (socketRef.current) {
		console.log('@socket Off');
            socketRef.current.emit('leave room', RoomNum);
            socketRef.current.off('connect_error');
            socketRef.current.off('new message');
            socketRef.current.disconnect();
            socketRef.current = null; // 소켓을 null로 설정해 다음에 다시 연결할 수 있도록 함
        }
    };
}, [RoomNum]); // RoomNum이 변경될 때만 소켓 설정 및 해제



	const hasInitialized = useRef(false);
	useEffect(() => {
   
	    const initialize = async () => {
		//채팅방에 소속된 인원 수 데이터 받아오기

		const showBlockList = async () => {
			const response = await axios.post(`${apiUrl}/BlockUserList`, {myToken: token});
			await setBlockList(response.data.users);
		};


		const updateRoomInfo = async () => {
			const response = await axios.post(`${apiUrl}/RoomTotalNum`, {myToken: token, rid: RoomNum});
			await setRoomUserNum(response.data);
			//2-1번째
		};

		const roomInfoSet = async () => {
			const response = await axios.post(`${apiUrl}/RoomInfo`, {myToken: token, rid: RoomNum});
			await setRoomInfo(response.data);
		};


		if (!hasInitialized.current) {
			await showBlockList();
			await roomInfoSet();
			await updateRoomInfo();
			hasInitialized.current = true; // 실행 후 플래그 설정
		}

		await fetchData();

		//메시지 끝으로 스크롤
		//messageEndRef.current.scrollIntoView({ behavior: 'instant' });

	    };
		initialize();

	},[RoomNum, token, page, hasMore]);


	const updateLastMessages = (newMsg) => {
		const response = axios.post(`${apiUrl}/LastMessageUpdate`, {newMsg: newMsg, myToken: token, rid: RoomNum});
	};


	const handleMsgSend = () => {
		if(message.trim()) { 
//			const newMessage = {
//				senderId: token,
//				message,
//				createAt: new Date(),
//			};
			//setMessages((prevMessages) => [...prevMessages, newMessage]);
			socketRef.current.emit('chat message', message, token, RoomNum);
			setMessage('');
		}
	};
///////////////////////////////////////////////////////////

	const handleScroll = (e) => {
		if (e.target.scrollTop === 0 && hasMore) {
			setPage(prevPage => {
				const newPage = prevPage + 1;
				fetchMessagesAndPreserveScroll(RoomNum, newPage, e.target);
				return newPage;
			});
		}
	};
	
	const fetchMessagesAndPreserveScroll = async (rid, page, container) => {

	    if (!container) return; // container가 null이면 함수 종료

	    const previousScrollHeight  = container.scrollHeight;
	    const previousScrollTop = container.scrollTop;
	    await fetchMessages(rid, page);
	    

	    setTimeout(() => {
		const newScrollHeight = container.scrollHeight;
		if (container.scrollTop === 0) {
		    container.scrollTop = newScrollHeight - previousScrollHeight;
		    //alert('fetchMessagesAndPreserveScroll : ', newScrollHeight);
		}
	    },100);
	};

	const formatTime = (date) => {
	    const options = { hour: '2-digit', minute: '2-digit', hour12: true };
	    return date.toLocaleString('ko-KR', options);
	};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    const year = date.getFullYear(); // 연도
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월 (0부터 시작하므로 +1)
    const day = String(date.getDate()).padStart(2, '0'); // 일
    const daysOfWeek = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];
    const dayOfWeek = daysOfWeek[date.getDay()]; // 요일
    
    // 형식을 YYYY년 MM월 DD일 (요일)로 반환
    return `${year}년 ${month}월 ${day}일 (${dayOfWeek})`;
};


////////////////////////////////////////////////////////////
	let lastDate = null; 
//////////////////////////////////////////////////////////
/////////////////////////신고하기//////////////////////////
///////////////////////////////////////////////////////////
	const [reportBellOn, setReportBellOn] = useState(false);
	const [reportOid, setReportOid] = useState('');
	const reportBell = (objectId) => {
	    if(objectId){
		setReportOid(objectId);

	    }
	    setReportBellOn(!reportBellOn);
	};
///////////////////////////////////////////////////////////
////////////////////////신고하기 끝////////////////////////
//////////////////////////////////////////////////////////

//######################클릭하면 보여주는 부분#################
        const [viewClassCheck, setViewClassCheck] = useState(false);
        const [viewClassId, setViewClassId] = useState(null);

        const handleViewClick = (classId) => {
            setViewClassId(classId);// viewClassId 상태를 업데이트합니다.
            setViewClassCheck(!viewClassCheck);
        };

        const handleCloseView = () => {
	    setViewClassCheck(!viewClassCheck);
        };
//############################################################

	const scrollToBottom = () => {
		messageEndRef.current?.scrollIntoView({ behavior: 'instant' });
		setNewMessage(null); // 미리보기 숨기기
	};

	  // 이미지 클릭 시 프리뷰 열기
	  const handleImageClick = (imageSrc) => {
	    setPreviewImage(imageSrc);
	    console.log(imageSrc);
	    setShowImagePreview(true);
	  };

	  // 프리뷰 닫기
	  const closeImagePreview = () => {
	    setShowImagePreview(false);
	    setPreviewImage(null);
	  };

	return(
	<div>
		
	      {/* 이미지 프리뷰 모달 */}
	      {showImagePreview && (
	        <div style={fullScreenImageStyle} onClick={closeImagePreview}>
	          <Image src={previewImage} style={{ maxWidth: '100%', maxHeight: '100%' }} />
	          <Button variant="light" style={{ position: 'fixed', top: '10px', right: '10px', fontSize: '0.7rem'}} onClick={closeImagePreview}>
	            닫기
	          </Button>
	        </div>
	      )}
		<ReportMessage show={reportBellOn} messageId={reportOid} handleClose={reportBell}/>
	    <Helmet>
		<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
	    </Helmet>
		<Row style={{paddingBottom: '10px', paddingTop: '10px', zIndex: 2, width: '100%', backgroundColor: '#ffffffcc', position: 'fixed', top: 0, left:'12px'}}>
		    <ChatMenu rid={RoomNum}/>
		    <h6 style={{marginBottom: 0, fontWeight: 'bold'}}>{roomInfo && (roomInfo.roomName)}</h6>
		    <div>
		    {
			    roomInfo&&(
				    roomInfo.loAge === "All" ?
				    	roomUserNum :
				   	ChatRoomNumSet(roomUserNum,50)
			    )
		    }
		    </div>
		</Row>
	{newMessage && newMessage.senderId !== currentUserId && !blockList?.includes(newMessage.senderId) && (
			<div style={previewBoxStyle} onClick={scrollToBottom}>
				<div style={previewMessageStyle}>
					{newMessage.senderId === "INNERDONG_ALERT" 
						? "INNERDONG 알림" 
						: `${newMessage.nname}: ${newMessage.mType === "c" 
							? "모임이 개최되었어요."
							: newMessage.mType === "i"
								? "이미지 전송" 
								: newMessage.message}`}
				</div>
			</div>
	)}
	<div className="scroll-container" style={scrollContainerStyle} onScroll={handleScroll} ref={scrollContainerRef}>
	    {messages.length < 1 &&(
		    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
			<h5><b>채팅을 시작해보세요! </b></h5>
		    </div>
		)
	    }
            {messages
		.filter((msg) => !blockList || !blockList.includes(msg.senderId)) // blockList에 없는 메시지만 남김
		.map((msg, index) => {

                const currentDate = new Date(msg.createAt).toDateString();
                const isNewDate = lastDate !== currentDate;
                lastDate = currentDate;

                return (
                    <React.Fragment key={index}>
                        {isNewDate && (
			    <Row style={changeDate}>
			        <Col xs={6} style={changeDateSub1}>{formatDate(msg.createAt)}</Col>
			        <Col xs={6}><hr style={{border: '1px dashed #888'}}/></Col>
		  	    </Row>)}
                            {msg.senderId === currentUserId ? (
                       		<div style={{ textAlign: 'right' }}>
                                    <Row className="mt-3" style={{ marginRight: 0 }}>
                                        <Col xs={12} style={{ padding: 0, display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                                <span style={timeArea}>{formatTime(new Date(msg.createAt))}</span>
				    	    {msg.mType === 'n' ? (
                                                <span style={{ ...messageArea2, ...messageStyle }} >{msg.message}</span>
					    ): msg.mType === 'i' ? (
						<Image src={msg.message} style={{...messageArea2, ...imageStyle }} onClick={() => handleImageClick(msg.message)} alt="Image" />
					    ):(
						msg.classDetails &&(
				                <div style={classAllMy}>
				                    <div className="text-center" style={fontSmall}>
				                    </div>
				                    <Row>
				                        <Row>
				                        </Row>
				                        <Row style={classBody}>
				                            <Ratio aspectRatio="1x1" style={marginTop2}>
				                                <Image src={checkType(msg.classDetails.ctype)} fluid rounded style={{padding: '15px'}} />
				                            </Ratio>
				                            <div>{msg.classDetails.cTitle}</div>
				                            <div>{formatDate(msg.classDetails.cOpenDate)}</div>
				                            <div>참여인원: ({msg.classDetails.cNowUser.length+1}/{msg.classDetails.cTotalNum})</div>
					                </Row>
				                        <Row style={buttonSpace0}>
			                            	    <Button variant="secondary" style={fontSizeSmall2} onClick={() => handleViewClick(msg.classDetails.classId)}>
			                                	내용보기
			                            	    </Button>
			                        	</Row>
			                    	    </Row>
			                	</div>
						)
					    )}
                                        </Col>
                                    </Row>
                                </div>
                            ) : (
                                <div style={{ textAlign: 'left' }}>
                                    <Row className="mt-3">
                                        <Col xs={2} style={{ marginTop: '0.3rem' }}>
                                            <Link to={'/friendProfile/' + msg.uid}>
                                                <Ratio aspectRatio="1x1">
                                                    <Image src={msg.pimage} fluid rounded />
                                                </Ratio>
                                            </Link>
                                        </Col>
                                        <Col xs={10} style={{ padding: 0 }}>
                                            <Row xs={10} style={nickNameStyle}>
                                                {msg.nname}
                                            </Row>
                                            <Row>
                                                <Col style={{display:'flex'}}>
				    		    {msg.mType === 'n' ? (
                                                        <span style={{ ...messageArea1, ...messageStyle }}>{msg.message}</span>
						    ) : msg.mType === 'i' ? (
							    <Image src={msg.message} style={{...messageArea1, ...imageStyle }} onClick={() => handleImageClick(msg.message)} alt="Image" />
						    ):(msg.classDetails &&(
					                <div style={classAllAthor}>
					                    <div className="text-center" style={fontSmall}>
					                    </div>
					                    <Row>
					                        <Row>
					                        </Row>
					                        <Row style={classBody}>
					                            <Ratio aspectRatio="1x1" style={marginTop2}>
					                                <Image src={checkType(msg.classDetails.ctype)} fluid rounded />
					                            </Ratio>
					                            <div style={{fontWeight: 'bold'}}>{msg.classDetails.cTitle}</div>
					                            <div>{formatDate(msg.classDetails.cOpenDate)}</div>
					                            <div>참여인원: ({msg.classDetails.cNowUser.length+1}/{msg.classDetails.cTotalNum})</div>
						                </Row>
					                        <Row style={buttonSpace0}>
				                            	    <Button variant="secondary" style={fontSizeSmall2} onClick={() => handleViewClick(msg.classDetails.classId)}>
				                                	내용보기
				                            	    </Button>
				                        	</Row>
				                    	    </Row>
				                	</div>
							)

						    )}
				    		    <div style={subArea}>
                                                        <span style={timeArea}>{formatTime(new Date(msg.createAt))}</span>
					  	        <span style={{color: 'red', fontSize: '0.7rem', fontWeight:'bold'}}> <Bell onClick={() =>reportBell(msg._id)} /> </span>
						    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                   </React.Fragment>
                );
            })}
	{viewClassCheck && (
			    <div>
				<ClassView classId={viewClassId} onCloseView={handleCloseView}/>
			    </div>
	)}

		<div style={{height:'56px'}}></div>
	    <div ref={messageEndRef}></div>
	</div>
		<Row style={ChatArea}>
		    <hr />
		    <div style={{padding:0, margin:0, width:'7%'}}>
			<span onClick={
			()=> window.ReactNativeWebView.postMessage(
				JSON.stringify({
					action: 'openPicker',
					token: token,
					rid: RoomNum,
				})
			)}>
			    <FileEarmarkImage />
			</span>
		    </div>
		    <Col xs={10} style={{paddingLeft:'2px', width:'80%'}}>
			<Form.Control
				as="textarea"
				style={FormArea}
				type="text"
				value={message}
				onChange={(e) =>
					setMessage(e.target.value)}
				inputMode="text"
			/>
		    </Col>
		    <Col xs={1} style={{padding: 0}}>
			<Button style={SendButton} onClick={handleMsgSend}>
			    <SendFill style={SendArea} />
			</Button>
		    </Col>
		</Row>
	</div>
	);
}

export default ChatRoom;
