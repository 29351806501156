import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
const token = localStorage.getItem('token');

function ChangeRoomName({ rid, setIsCancelButtonVisible }) {
    const { lo } = useParams();
    const [currentRoomName, setCurrentRoomName] = useState('');
    const [currentRoomDate, setCurrentRoomDate] = useState('');
    const [newRoomName, setNewRoomName] = useState('');
    const [remainingChars, setRemainingChars] = useState(20); // 남은 글자 수 상태

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [redirectToChatting, setRedirectToChatting] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post(`${apiUrl}/ChatRoomInfo`, { myToken: token, rid: rid });
            const currentRoom = response.data;
	    console.log('currentRoom : ',response.data);
            if (currentRoom) {
                setCurrentRoomName(currentRoom.roomName);
		setCurrentRoomDate(currentRoom.changeAt);
            }
        };
        fetchData();
    }, [rid]);

    const handleNewRoomNameChange = (e) => {
        const newName = e.target.value;
        setNewRoomName(newName);
        setRemainingChars(20 - newName.length); // 남은 글자 수 업데이트
    };

    const clickChangeRoomName = async () => {
        if (newRoomName.trim() === '') {
            setModalMessage('변경할 채팅방 이름을 입력하세요.');
            setShowModal(true);
            return;
        }

        const response = await axios.post(`${apiUrl}/ChangeRoomName`, { myToken: token, newRoomName, nowRid: rid });
        if (response.data === 'timeError') {
            setModalMessage('아직 시간이 되지 않았어요');
            setShowModal(true);
        } else if (response.data === 'sameName') {
            setModalMessage('채팅방 이름이 똑같아요.');
            setShowModal(true);
        } else if (response.data === 'fail'){
            setModalMessage('채팅방 이름 변경에 실패했어요.');
            setShowModal(true);
        } else if (response.data === ''){
	    setModalMessage('채팅방 이름을 변경했어요.');
	    setRedirectToChatting(true);
	    setShowModal(true);
	}
    };

  // ISO 형식의 날짜를 "YYYY-MM-DD HH:MM" 형식으로 변환하는 함수
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);

    // 연, 월, 일, 시, 분 추출
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더함
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // "YYYY-MM-DD HH:MM" 형식으로 변환
    return `${year}-${month}-${day} ${hours}시 ${minutes}분`;
  };

    return (
<div
    style={{
        position: 'fixed', // 화면에 고정
        top: '50%', // 세로 가운데 정렬
        left: '50%', // 가로 가운데 정렬
        transform: 'translate(-50%, -50%)', // 정확히 중앙에 배치
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', // 화면 전체 높이
    }}
>
    <Container
        style={{
            width: '350px',
            padding: '20px',
            border: '2px solid #4a4a4a',
            borderRadius: '10px',
            backgroundColor: 'white',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
        className="d-flex flex-column align-items-center justify-content-center"
    >
	    <h3 style={{ marginBottom: '20px', fontWeight: 'bold', fontSize: '1rem' }}>채팅방 이름변경</h3>
            <Form style={{ fontSize: '0.8rem' }}>
                <Form.Group as={Row} className="mb-3" controlId="formCurrentRoomName">
                    <Col xs={3}>
                        <Form.Label style={{ fontSize: '0.8rem' }}>변경 전</Form.Label>
                    </Col>
                    <Col>
                        <Form.Control type="text" readOnly value={currentRoomName} style={{ backgroundColor: '#ffca5a', fontSize: '0.8rem' }} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formNewRoomName">
                    <Col xs={3}>
                        <Form.Label style={{ fontSize: '0.8rem' }}>변경 후</Form.Label>
                    </Col>
                    <Col>
                        <Form.Control
                            type="text"
                            value={newRoomName}
                            onChange={handleNewRoomNameChange}
                            maxLength={20}
                            style={{ fontSize: '0.8rem' }}
                        />
                    </Col>
                </Form.Group>

                <div style={{ textAlign: 'right', marginBottom: '10px', color: '#999', fontSize: '0.8rem' }}>
                    {remainingChars}/20
                </div>

                <p style={{ color: '#666', fontSize: '0.7rem', textAlign: 'left' }}>
                    ※ 채팅방 이름은  일주일에 한번밖에 변경 할 수 없어요.<br/>
	            마지막 변경일 <b>{currentRoomDate ? formatDate(currentRoomDate) : '없음'}</b>
                </p>
	        <p style={{ color: 'red', fontSize: '0.8rem', textAlign: 'center' }}>
	    	    {modalMessage}
	        </p>

                <Row className="d-flex justify-content-around" style={{ marginTop: '20px' }}>
	    	    <Col style={{width: '100%'}}>
                    <Button variant="warning" onClick={clickChangeRoomName} style={{ fontSize: '0.8rem', width:'100% '}}>
                        변경
                    </Button>
	    	    </Col>
	    	    <Col style={{width: '100%'}}>
                    <Button variant="light" onClick={() => setIsCancelButtonVisible(false)} style={{ fontSize: '0.8rem', width: '100%'}}>
                        취소
                    </Button>
	    	    </Col>
                </Row>
            </Form>
        </Container>
    </div>
    );
}

export default ChangeRoomName;
