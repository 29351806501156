import {Container, Row, Col, Button, Image, Ratio, Modal} from 'react-bootstrap';
import {CursorFill} from 'react-bootstrap-icons';
import React, {useState, useEffect, useRef} from 'react';
import {isEqual} from 'lodash';
import {useParams, Link} from 'react-router-dom';
import axios from 'axios';

const fontSizeSmall={width: '100%'};

function ConfirmLocation(props) {
  const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
  const token = localStorage.getItem('token');

  const [paramsLo, setParamsLo] = useState('myLocation');
  const paramsLoRef = useRef(paramsLo); // 최신 상태값 참조
  const [myInfo, setData] = useState(null);
  const [nowLocation, setNowLo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleClose = () => setShowModal(false);

  // 상태 업데이트를 중복되지 않도록 방지
  useEffect(() => {
    const tempParamsLo = props.paramslo;
    const newParamsLo = isEqual(tempParamsLo, 'oftenLocation') ? 'oftenLocation' : 'myLocation';

    // 상태 업데이트가 중복되지 않도록 조건 추가
    if (paramsLo !== newParamsLo) {
      setParamsLo(newParamsLo);
      paramsLoRef.current = newParamsLo; // useRef로도 최신값 유지
    }
    console.log('paramsLo updated to: ', paramsLo);
  }, [props.paramslo]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.post(`${apiUrl}/ConfirmLocation`, { myToken: token });
      setData(response.data);

      const myLocation = response.data.loginUser.mainlo;
      const oftenLocation = response.data.loginUser.sublo;

      // 현재 상태를 기반으로 위치 설정
      setNowLo(isEqual(paramsLoRef.current, 'oftenLocation') ? oftenLocation : myLocation);
      console.log('#####paramsLo: ', paramsLoRef.current);
    };

    // paramsLo가 변경되었을 때에만 데이터를 가져옴
    fetchData();
  }, [paramsLo, token]);  // paramsLo가 변경될 때마다 실행되므로 중복 호출되지 않음

  const requestLocation = () => {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      //window.ReactNativeWebView.postMessage('getLocation');
      window.ReactNativeWebView.postMessage(
	      JSON.stringify({
		      action: 'getLocation'
	      })
      );
    } else {
      console.error('ReactNativeWebView is not defined.');
      alert('This function can only be used within the WebView.');
    }
  };

  const receiveLocation = async (event) => {
    try {
      const locationData = JSON.parse(event.data);
      const { latitude, longitude } = locationData;

      const response = await axios.post(`${apiUrl}/GeoCode`, {
        latitude,
        longitude,
        myToken: token,
        paramsLo: paramsLoRef.current, // 최신 paramsLo 값 사용
      });

      switch (response.data.res) {
        case 'Y':
          setNowLo(response.data.fullAddress);
          localStorage.setItem('token', token);
          setModalMessage(response.data.message);
          setShowModal(true);
          break;

        case 'SP':
          setModalMessage(response.data.message);
          setShowModal(true);
          break;

        default:
          console.warn('Unknown response code:', response.data.res);
          break;
      }
    } catch (error) {
      console.error('Failed to parse location data:', error);
    }
  };

  useEffect(() => {
    const messageHandler = (event) => {
      receiveLocation(event);
    };

    // 이벤트 리스너 중복 등록 방지
    window.addEventListener('message', messageHandler);
    document.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
      document.removeEventListener('message', messageHandler);
    };
  }, []);  // 이벤트 리스너는 컴포넌트 마운트 시에만 등록

  return (
  <div style={{backgroundColor: '#e9ebf5', borderderBottomLeftRadius: '1rem', borderBottomRightRadius: '1rem'}}>
    <div style={{padding: '10px', paddingTop: '20px'}}>
      <Modal show={showModal} centered>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer style={{ textAlign: 'center', display: 'block' }}>
          <Button variant="secondary" onClick={handleClose}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
      <h3><b>동네인증</b></h3>
      <Row className={fontSizeSmall}>
        <Col>
          <Link to="/location/myLocation">
            <Button variant="outline-warning" style={fontSizeSmall} active={isEqual(paramsLo, "myLocation")}>우리동네</Button>
          </Link>
        </Col>
        <Col>
          <Link to="/location/oftenLocation">
            <Button variant="outline-warning" style={fontSizeSmall} active={isEqual(paramsLo, "oftenLocation")} style={{backgroundColor: 'white', width: '100%'}}>자주가는동네</Button>
          </Link>
        </Col>
      </Row>


    <div style={styles.container}>
      {/* 배경 이미지 */}
      <div style={styles.backgroundContainer}>
        {/* 텍스트와 인증 버튼 */}
      </div>
        <div style={styles.content}>
          <div style={styles.textContainer}>
             {nowLocation ? nowLocation : <b style={{ color: '#ffc107', textDecorationLine: 'underline' }}> 위치인증 필요</b>}
          </div>
          <Button
            variant="warning"
            style={{ marginLeft: '0', fontSize: '0.8rem', fontWeight: 500 }}
            onClick={requestLocation}
          >
            <CursorFill />현재위치 공유
          </Button>
        </div>
      </div>
    </div>
  </div>
  );
}

// 스타일 정의
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
    marginTop: '0.1rem',
  },
  backgroundContainer: {
    position: 'relative',
    width: '100%',
    height: '95%',
    backgroundImage: "url('/worldIcon.png')", // 배경 이미지 경로
    backgroundPosition: 'center', // 이미지가 중앙에 위치
    backgroundRepeat: 'no-repeat', // 이미지 반복을 방지
    backgroundSize: 'cover',
    opacity: '0.5',
    marginTop: '10px',
    borderRadius: '0.7rem',
  },
  content: {
    position: 'absolute',
    textAlign: 'center',
    zIndex: 1, // 배경 위에 텍스트 및 버튼 배치
  },
  textContainer: {
    padding: '10px 15px',
    borderRadius: '10px',
    marginBottom: '10px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#333',
    width: '18rem',
    backgroundColor: '#ffffffc7',
  },
  button: {
    padding: '5px 10px',
    fontSize: '14px',
  },
};


export default ConfirmLocation;

