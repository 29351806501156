import {Row, Col, Button, Image, Ratio, Container, Modal, Form} from 'react-bootstrap';
import {useState, useEffect} from 'react';
import axios from 'axios';
import {useParams, useNavigate} from 'react-router-dom';

const rowStyle = {marginTop: '0.3rem', marginBottom: '0.3rem'};
const titleStyle = {fontWeight: 'bold'};
const contentStyle = {textAlign: 'right'};
const connectCheckStyle ={fontSize: '0.8rem', width: '100%'};
const secessionAgreeStyle = {padding: '0 1rem 1rem 1rem', fontSize: '0.8rem'};

const formatDate = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

function SettingMyProfile(uid) {

    const Params = useParams();
    const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
    const token = localStorage.getItem('token');



    //데이터 세팅
    const [myUid, setMyUid] = useState('');
    const [myNickName, setMyNickName] = useState('');
    const [myBirth, setMyBirth] = useState('');
    const [mySex, setMySex] = useState('');
    const [myPhone, setMyPhone] = useState('');
    const [myCreateAt, setMyCreateAt] = useState('');

    useEffect(() => {
	const fetchData = async() => {
	    const response = await axios.post(`${apiUrl}/SettingMyProfile`, { myToken: token });

	    console.log('SettingMyProfile : ',response);
	    setMyUid(response.data.uid);
	    setMyNickName(response.data.nname);
	    setMyBirth(response.data.birth);
	    setMySex(response.data.sex);
	    setMyPhone(response.data.phone);
	    setMyCreateAt(response.data.time);
	};

	fetchData();

    },[]);


    const [checkedItems, setCheckedItems] = useState(false);
    const handleCheckboxChange = (event) => {
        setCheckedItems(event.target.checked);
    };
 
    const navigate = useNavigate();
    const [textAreaValue, setTextAreaValue] = useState('');
    const handleChange = (event) => {
	setTextAreaValue(event.target.value);
    };

    const [logoutBtn, setLogoutBtn] = useState(false);
    const handleLogoutSet = () => {
	setLogoutBtn(!logoutBtn);
	console.log('logoutBtn : ', logoutBtn);
    };

    const handleLogoutClick = () => {
	localStorage.removeItem('token');
	navigate('/login');
	console.log('handleLogoutClick');
    };


    return(
	<Container>
	    <hr />
	    <div style={{fontSize:'1rem'}}>
	        <Row style={rowStyle}>
	    	    <Col xs={4} style={titleStyle}>닉네임</Col>
	    	    <Col style={contentStyle}>{myNickName}</Col>
	        </Row> 
	        <Row style={rowStyle}>
	    	    <Col xs={4} style={titleStyle}>생년월일</Col>
	    	    <Col style={contentStyle}>{myBirth}</Col>
	        </Row>
	        <Row style={rowStyle}>
	            <Col xs={4} style={titleStyle}>성별</Col>
	    	    <Col style={contentStyle}>{mySex === "M" ? "남성" : "여성"}</Col>
	        </Row>
	        <Row style={rowStyle}>
	    	    <Col xs={4} style={titleStyle}>핸드폰번호</Col>
	    	    <Col style={contentStyle}>{myPhone}</Col>
	        </Row>
	        <Row style={rowStyle}>
	    	    <Col xs={4} style={titleStyle}>가입일</Col>
	    	    <Col style={contentStyle}>{formatDate(myCreateAt)}</Col>
	        </Row>
	    </div>
	    <hr />
	    <Row style={{textAlign:'right'}}>
	        <Col style={{textAlign:'right'}}>
	    	    <span style={{color:'black'}} onClick={()=>handleLogoutSet()}>로그아웃</span>
	        </Col>
	    </Row>
	    {logoutBtn && (
                <div>
                    <Modal show={logoutBtn} centered>
                        <Modal.Body style={{textAlign:'center', marginTop: '1rem', fontWeight: 'bold'}}>로그아웃 하시겠습니까?</Modal.Body>
		    	    <Row style={{marginBottom: '2rem'}}>
                                <Col></Col>
                                    <Col>
                                        <Button variant="secondary" onClick={() => handleLogoutClick()} style={connectCheckStyle}>
                                        네
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button variant="warning" onClick={handleLogoutSet} style={connectCheckStyle}>
                                        아니오
                                        </Button>
                                    </Col>
                                <Col></Col>
                            </Row>
                     </Modal>
                 </div>
	    )}

	</Container>
    );

}

export default SettingMyProfile;
