import { Row, Col, Button, Container } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';
import { useState, useEffect } from 'react';
import { NotionRenderer } from 'react-notion-x';
import axios from 'axios';
import 'react-notion-x/src/styles.css';
import GoBack from '../GoBack.js';
import Loading from '../Loading'; // 로딩 컴포넌트 임포트

const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
const token = localStorage.getItem('token');

const tableStyle = { width: '100%' };
const titleStyle = { fontWeight: 'bold', textAlign: 'center', backgroundColor: '#eeeeee', borderRadius: '1rem', padding: '0.5rem' };
const contentDetailStyle = { fontSize: '0.7rem', position: 'absolute', top: 0, left: 0, width: '100%', height: '100vh', backgroundColor: 'white', padding: '1rem', textAlign: 'left' };

function SettingNotion() {
    const [notionData, setNotionData] = useState([]);
    const [loading, setLoading] = useState(true); // 로딩 상태 추가
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [recordMap, setRecordMap] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/NotionDataAll`, {
                    params: {
                        page: currentPage,
                        itemsPerPage: 20
                    }
                });
                setNotionData(response.data.items);
                setTotalPages(response.data.totalPages);
                setLoading(false); // 데이터 로딩이 완료되면 로딩 상태 해제
            } catch (error) {
                console.error(error);
                setLoading(false); // 오류가 발생해도 로딩 상태 해제
            }
        };

        fetchData();
    }, [currentPage]);

    const handleShowNotice = async (noticeId) => {
        try {
            const response = await axios.post(`${apiUrl}/ShowNotice`, { noticeId: noticeId });
            setRecordMap(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCloseNotice = () => {
        setRecordMap(null);
    };

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
            setLoading(true); // 페이지 변경 시 로딩 상태 설정
        }
    };

    const mapImageUrl = (url, block) => {
        if (url.startsWith('https://www.notion.so/image/')) {
            const imageUrl = new URL(url);
            const s3Url = imageUrl.searchParams.get('url');
            return s3Url ? decodeURIComponent(s3Url) : url;
        }
        return url;
    };

    return (
        <div>
	    {!recordMap && <GoBack />}
            <Container style={{ marginTop: '1.5rem', textAlign: 'left', marginTop: '35px' }}>
                {loading ? (
                    <Loading loading={loading} /> // 로딩 중일 때 Loading 컴포넌트 표시
                ) : (
                    <div>
                        <table cellPadding="9" cellSpacing="0" style={tableStyle}>
                            <thead>
                                <tr style={{ backgroundColor: '#eeeeee' }}>
                                    <th style={{ textAlign: 'center' }}> 공지제목 </th>
                                    <th style={{ textAlign: 'center' }}> 공지날짜 </th>
                                </tr>
                            </thead>
                            <tbody>
                                {notionData && notionData.map((page) => (
                                    <tr key={page.id} onClick={() => handleShowNotice(page.id)}>
                                        <td>{page.properties['이름'].title[0].plain_text}</td>
                                        <td style={{ textAlign: 'right' }}>{page.properties['공지날짜'].date.start}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                            <Button variant='light' style={{fontSize:'0.8rem'}} disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>이전</Button>
                            <span style={{ margin: '0 1rem' }}>{currentPage} / {totalPages}</span>
                            <Button variant='light' style={{fontSize:'0.8rem'}}disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>다음</Button>
                        </div>
                    </div>
                )}
            </Container>
            {recordMap && (
                <div style={contentDetailStyle}>
                    <div>
                        <div style={{textAlign:'right', position: 'absolute', zIndex: 1, top: '10px', right: '10px', padding: '10px'}} onClick={handleCloseNotice}>
		    	    <X style={{bolor: 'black'}} size={20} />
		        </div>
                    </div>
                    <NotionRenderer style={{ textAlign: 'left' }}
                        recordMap={recordMap}
                        fullPage={true}
                        mapImageUrl={mapImageUrl}
                    />
                </div>
            )}
        </div>
    );
}

export default SettingNotion;


