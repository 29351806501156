import React, {useState, useEffect} from 'react';
import {Row, Col, Button, Image, Ratio, Container} from 'react-bootstrap';
import {ChevronCompactRight} from 'react-bootstrap-icons';
import {Link} from 'react-router-dom';
import axios from 'axios';
import GoBack from '../GoBack.js';

function BlockUsers() {

	const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;

	//데이터 받아오기 JWT 복호화 데이터
        const [blockUsers, setBlockUsers] = useState(null);
        useEffect(()=>{
	        const fetchData = async() => {
			const token = localStorage.getItem('token');
			console.log(token);
			const response = await axios.post(`${apiUrl}/BlockUserList`,{ myToken: token, allInfo: true });
                        await setBlockUsers(response.data.users);
                        await console.log(response.data.users);
                };  
                fetchData();
        },[]);


	let viewBlockUsersList = null;
	let numberOfBlockUsers = 0;

	if (blockUsers) {
	    // 차단된 사용자 목록을 렌더링
	    viewBlockUsersList = blockUsers.map((v) => (
	        <Row style={{ marginBottom: '10px' }} className='justify-content-center align-items-center'>
	            <Link to={'/friendProfile/' + v.uid} className='justify-content-center align-items-center' style={{ display: 'contents', textDecoration: 'none', color: "black" }}>
	                <Col xs={2} style={{ padding: '3px' }}>
	                    <Ratio aspectRatio="1x1">
	                        <Image src={v.pimage} roundedCircle fluid />
	                    </Ratio>
	                </Col>
	                <Col xs={9} style={{ paddingLeft: "10px" }}>
	                    <div>
	                        <Row><b>{v.nname}</b></Row>
	                        <Row style={{ paddingLeft: '0' }}>
	                            <Col style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: '#787878' }} xs={12}>
	                                {v.message ? v.message : '\u00A0'}
	                            </Col>
	                        </Row>
	                    </div>
	                </Col>
	            </Link>
	        </Row>
	    ));
	    numberOfBlockUsers = viewBlockUsersList.length;
	}


	return (
        <div style={{padding: '10px' , paddingTop: '20px'}}>
		<GoBack />
                <h3>
                  <b> 차단 사용자 목록</b>
                    <p style={{fontSize: 'calc(0.9rem + 0.1vw)', marginLeft: '5px'}}> {numberOfBlockUsers}명</p>
                </h3>
            <Container fluid="md" style={{textAlign: 'left', padding: 0}}>
              <Row>
                {viewBlockUsersList && viewBlockUsersList.length > 0 ? (
                        viewBlockUsersList
                ) : (
                    <div style={{textAlign:'center', paddingTop: '3rem'}}>
                        <Image src='/noFriend.png' style={{maxWidth: '30%'}} />
                        <p style={{marginTop: '1rem'}}>차단한 사용자가 없어요</p>
                    </div>
                )}
              </Row>
            </Container>
          <br/><br/>
        </div>

	);
}

export default BlockUsers;
